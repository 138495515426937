import Vue from 'vue'
import Vuex from 'vuex'

import app from '@/store/modules/app/app'
import auth from '@/store/modules/app/auth'
import company from '@/store/modules/company/company'
import accounts from '@/store/modules/accounts/accounts'
import purchaseForm from '@/store/modules/purchase/purchaseForm'
import product from '@/store/modules/product/product'
import saleForm from '@/store/modules/sale/saleForm'
import productsPrintTag from "@/store/modules/product/productsPrintTag"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    auth,
    company,
    accounts,
    purchaseForm,
    product,
    saleForm,
    productsPrintTag

  }
})
