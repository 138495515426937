import Vue from 'vue'
import SaleObject  from "./../../../services/sale/SaleObject"
import saleValidator from "./../../../services/sale/saleValidator"

var saleForm = {
  id: null,
  company: null,
  customer: null,
  salesman: null,
  operation: null,
  has_negotiation: 0,
  delivery_type: null,
  negotiation_value: null,
  negotiation_comments: null,
  sale_products: [],
  general_discount: 0,
  general_discount_percent: 0,
  discount: 0,
  discount_percent: 0,
  sale_payment_terms: [],
  sale_bills: [],
  notes : null,
  status : null,
}

export default {

  namespaced: true,

  state: () => ({

    saleProductEditIndex: null,

    baseSaleForm: saleForm,

    saleForm: saleForm,

  }),

  getters: {

    isInsert: (state) => {
      return state.saleForm.id == null
    },

    sale : (state) => {
      let saleObject = new SaleObject()
      
      saleObject.setSale(state.saleForm)
      
      return saleObject.getSaleForm()
    },

    hasProducts: (state) => {
      return state.saleForm.sale_products.length > 0
    },

    maxDiscountAllowed: (state, getters) => {
      return Math.min(...state.saleForm.sale_payment_terms.filter(row => (row.payment_term)).map(row => parseFloat(row.payment_term.max_discount_percent)))
    },
    
    errorMessages : (state, getters) => {
      return saleValidator(state.saleForm)
    },

    hasErrors : (state, getters) => {
      return getters.errorMessages.length > 0
    }

  },


  mutations: {

    reset(state) {
      state.saleForm = JSON.parse(JSON.stringify(state.baseSaleForm));
    },
    
    setSale(state, sale) {
      state.saleForm = JSON.parse(JSON.stringify(sale)) 
    },

    setSaleCopy(state, sale) {
      
      sale.id = null
      sale.status = null

      state.saleForm = JSON.parse(JSON.stringify(sale))
    },
    
    setOperation(state, operation) {
      state.saleForm.operation = operation
    },

    setCompany(state, company) {
      state.saleForm.company = company
    },

    setSalesman(state, salesman) {
      state.saleForm.salesman = salesman
    },

    setCustomer(state, customer) {
      state.saleForm.customer = customer
    },

    setDeliveryType(state, deliveryType) {
      state.saleForm.delivery_type = deliveryType
    },

    addProduct(state, sale_product) {
      state.saleForm.sale_products.push(sale_product);
    },

    updateProduct(state, { saleProduct, index }) {
      let newArr = JSON.parse(JSON.stringify(state.saleForm.sale_products));

      newArr[index] = saleProduct;

      state.saleForm.sale_products = newArr;
    },

    removeProduct(state, index) {
      state.saleForm.sale_products.splice(index, 1);
    },

    setGeneralDiscount(state, discount) {
      state.saleForm.general_discount = discount
    },

    setSalePaymentTerms(state, salePaymentTerms) {
      state.saleForm.sale_payment_terms = salePaymentTerms
    },

    setSaleBills(state, saleBills) {
      state.saleForm.sale_bills = saleBills
    },

    setStatus(state, status) {
      state.saleForm.status = status
    },

    setNotes(state, notes) {
      state.saleForm.notes = notes
    },

    setNegotiation(state, negotiation) {
      state.saleForm.negotiation_value = negotiation?.negotiation_value
      state.saleForm.negotiation_comments = negotiation?.negotiation_comments
      state.saleForm.has_negotiation = negotiation?.has_negotiation
    }

  },

  actions: {

    async storeSale({ commit, state }) {
      if (state.saleForm.id) {
        return this.dispatch("saleForm/update");
      } else {
        return this.dispatch("saleForm/store");
      }
    },

    async store({ commit, state }) {
      
      let saleObject = new SaleObject()
      
      saleObject.setSale(state.saleForm)

      return new Promise((resolve, reject) => {
        Vue.$http
          .store("sale/sale", saleObject.getSaleParams())
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },

    async update({ commit, state }) {

      let saleObject = new SaleObject()

      saleObject.setSale(state.saleForm)

      return new Promise((resolve, reject) => {
        Vue.$http
          .update("sale/sale", state.saleForm.id, saleObject.getSaleParams())
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },

    async loadSale({ commit, state }, id) {
      Vue.$http
        .show("sale/sale", id)
        .then(response => {
          commit('setSale', response.sale)
        })
        .catch(error => {

        })
    },

    async copySale({ commit, state }, id) {
      Vue.$http
        .show("sale/sale", id)
        .then(response => {
          commit('setSaleCopy', response.sale)
        })
        .catch(error => {

        })
    }
  }
}