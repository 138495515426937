import Vue from 'vue'

export default {

  namespaced: true,

  state: () => ({

    productIndex: null,
    form: {},
    baseForm: {
      number: null,
      serie: null,
      cfop: null,
      emitted_at: null,
      entry_at: null,
      mod: null,
      chave_nfe: null,
      saved_at: null,
      purchased_at: null,
      operation_id: null,
      company_id: null,
      supplier_id: null,
      canceled_at: null,
      stock_processed_at: null,
      finance_processed_at: null,
      product_total: null,
      discount: null,
      icms_calc_base: null,
      icms: null,
      icms_st_calc_base: null,
      icms_st_value: null,
      ipi_calc_base: null,
      ipi: null,
      shipping: null,
      other_expenses: null,
      insurance: null,
      net_total: null,
      raw_xml: null,
      status: null,
      stock_status: null,
      finance_status: null,
      type: 'invoice',
      operation: 'product_receipt',
      shipping_method: null,
      supplier: {},
      company: {},
      purchase_bills: [
        {
          document: null,
          value: null,
          due_date: null,
          payment_method: null
        }
      ],
      purchase_products: [

      ]
    },



    purchase_product: {
      cest: null,
      cfop: null,
      total: 0,
      csosn: null,
      cst: null,
      discount: 0,
      gtin: null,
      icms_aliquot: 0,
      icms_calc_base: 0,
      icms_st_value: 0,
      icms_st_aliquot: 0,
      icms_st_calc_base: 0,
      icms_value: 0,
      insurance: 0,
      ipi_aliquot: 0,
      ipi_calc_base: 0,
      ipi_value: 0,
      manufacturer_id: null,
      ncm: null,
      other_expenses: 0,
      product: 0,
      product_total: 0,
      purchase_multiple: 1,
      purchase_price: 0,
      quantity: 0,
      quantity_nf: 0,
      shipping: 0,
      st_aliquot: 0,
      st_value: 0,
    },

    xmlName: null,

    purchase: {
      supplier: {},
      company: {},
      products: [],
      bills: [{
        document: null,
        due_date: null,
        value: null,
      }],
    },
  }),

  getters: {
    fileLabel: state => {
      return state.xmlName == null ? "Abrir arquivo XML" : state.xmlName;
    }
  },

  mutations: {

    resetForm(state) {
      state.xmlName = null
      state.form = JSON.parse(JSON.stringify(state.baseForm));
    },

    setFile(state, { fileData, fileName }) {
      state.xmlName = fileName
      state.form = fileData
    },

    addBill(state) {
      state.form.purchase_bills.push({
        document: null,
        due_date: null,
        value: null,
      });
    },

    deleteBill(state, billIndex) {
      state.form.purchase_bills.splice(billIndex, 1);
    },

    deleteProduct(state, productIndex) {
      state.form.purchase_products.splice(productIndex, 1);
    },

    editProduct(state, productIndex) {
      state.productIndex = productIndex;
      state.purchase_product = state.form.purchase_products[productIndex];
    },

    addProduct(state, product) {

      state.form.purchase_products.push(product);
    },

    updateProduct(state, { product, productIndex }) {

      Vue.set(state.form.purchase_products, productIndex, product);
    },

    setSupplier(state) {
      state.form.purchase_products.forEach(purchase_product => {
        purchase_product.product.supplier = state.form.supplier;
      })
    },

    linkProduct(state, { product, productIndex }) {
      let productData = {}
      productData = state.form.purchase_products[productIndex]
      productData.product = product
      productData.name = product.name
      Vue.set(state.form.purchase_products, productIndex, productData);

    },


  },

  actions: {

    async showPurchase({ state }, purchase_id) {
      await Vue.$http
        .show('purchase/purchase', purchase_id)
        .then((response) => {
          state.form = JSON.parse(JSON.stringify(response.purchase));
        })
        .catch((error) => { });
    }

  }

}
