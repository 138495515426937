<template>
  <v-dialog v-model="dialog" persistent max-width="700px">
    <v-card>
      <v-card-title primary-title> Análise de Crédito </v-card-title>
      <v-card-text>
        <v-form ref="form" lazy-validation :readonly="isView">
          <v-row>
            <v-col>
              <app-select
                v-model="form.spc_check"
                :items="options"
                label="Consulta SPC"
              />
            </v-col>
            <v-col>
              <app-select
                v-model="form.serasa_check"
                :items="options"
                label="Consulta Serasa"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                v-model="form.spc_check_details"
                label="Detalhe da  consulta em SPC"
                hide-details
                auto-grow
                rows="1"
              />
            </v-col>
            <v-col>
              <v-textarea
                v-model="form.serasa_check_details"
                label="Detalhe da  consulta em serasa"
                hide-details
                auto-grow
                rows="1"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <app-number-field
                v-model="form.credit_limit"
                label="Limite de Crédito"
              />
            </v-col>
            <v-col>
              <app-text-field
                v-model="form.due_date"
                type="date"
                label="Vencimento da Avaliação:"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-textarea
                v-model="form.notes"
                label="Observações"
                hide-details
                auto-grow
                outlined
                rows="2"
              />
            </v-col>
          </v-row>

          <v-switch
            v-model="form.credit_status"
            label="Credito Aprovado"
            false-value="rejected"
            true-value="approved"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="dialog = false" text color="secondary">Cancelar</v-btn>
        <v-btn v-if="!isView" @click="handleSave()" color="primary">
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { add } from "date-fns";
import format from "date-fns/format";

export default {
  data() {
    return {
      dialog: false,

      options: [
        { value: "restrictions", text: "Com Restrições" },
        { value: "not_checked", text: "Não Consultado" },
        { value: "clear", text: "Nada Consta" },
      ],

      form: {},
      baseForm: {
        id: null,
        customer_id: null,
        due_date: format(add(new Date(), { days: 90 }), "yyyy-MM-dd"),
        spc_check: "not_checked",
        spc_check_details: null,
        serasa_check_details: null,
        serasa_check: "not_checked",
        credit_limit: 0,
        notes: null,
        credit_status: "rejected",
        status: 1,
      },
    };
  },

  created() {
    this.reset();
  },

  computed: {
    isView() {
      return this.form.id != null;
    },
  },

  methods: {
    open(customer_id, id = null) {
      this.reset();

      this.form.customer_id = customer_id;

      if (id) {
        this.show(id);
      }

      this.dialog = true;
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },

    handleSave() {
      this.$loading.start();

      this.$http
        .store("customer/customer-credit-analysis", this.form)
        .then((response) => {
          this.$emit("store");
          this.$loading.finish();
          this.dialog = false;
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
    show(id) {
      this.$loading.start();

      this.$http
        .show("customer/customer-credit-analysis", id)
        .then((response) => {
          this.form = JSON.parse(JSON.stringify(response.credit_analyses));
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
  },
};
</script>

<style>
</style>