import Vue from "vue"


class App {

  constructor(options) { }




  get isMobile() {

    return window.innerWidth < 600
  }



}

export default App