<template>
  <div>
    <v-row align-end>
      <v-chip-group class="mt-auto" v-model="address.type" mandatory>
        <v-chip
          v-if="address.type == 'mainAddress'"
          value="mainAddress"
          color="success"
          small
          filter
          >Principal
        </v-chip>
        <v-chip
          v-if="address.type != 'mainAddress'"
          value="delivery"
          color="secondary"
          small
          filter
          >Entrega</v-chip
        >
        <v-chip
          v-if="address.type != 'mainAddress'"
          value="billing"
          color="primary"
          small
          filter
        >
          Cobrança</v-chip
        >
      </v-chip-group>
      <v-col class="pb-0" md="4">
        <app-cep-field
          v-model="address.postal_code"
          @address="handleCepInput($event)"
        />
      </v-col>

      <v-btn
        v-if="address.type != 'mainAddress'"
        class="ml-auto mt-auto text-capitalize"
        primary
        x-small
        text
        @click="handleRemove()"
      >
        Remover
        <v-icon x-small>mdi-close</v-icon>
      </v-btn>
    </v-row>

    <v-row>
      <v-col cols="12" md="5">
        <app-text-field v-model="address.address" label="Endereço" />
      </v-col>
      <v-col md="2">
        <app-text-field v-model="address.number" label="Número" />
      </v-col>
      <v-col md="5">
        <app-text-field v-model="address.complement" label="Complemento" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="5">
        <app-text-field v-model="address.district" label="Bairro" />
      </v-col>
      <v-col md="5">
        <app-text-field v-model="address.city" label="Cidade" readonly />
      </v-col>
      <v-col md="2">
        <app-text-field v-model="address.state" label="Estado" readonly />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
  },
  data() {
    return {
      address: {
        address: null,
        addressable_id: null,
        addressable_type: null,
        city: null,
        city_code: null,
        complement: null,
        country: null,
        country_code: null,
        created_at: null,
        district: null,
        id: null,
        number: null,
        postal_code: null,
        state: null,
        state_code: null,
        status: null,
        type: null,
      },
    };
  },

  watch: {
    value(val) {
      this.setAddress(val);
    },

    address: {
      handler(val) {
        this.$emit("input", val);
      },
      deep: true,
    },
  },

  created() {
    this.setAddress(this.value);
  },

  methods: {
    setAddress(value) {
      this.address = value;
    },

    handleCepInput(address) {
      this.address.city = address.localidade;
      this.address.state = address.uf;
      this.address.city_code = address.ibge;
      this.address.postal_code = address.cep;
    },

    handleRemove() {
      this.address.status = 0;
    },
  },
};
</script>

<style>
</style>
