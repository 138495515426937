
import PrintTag from '../utils/printTag'
import Vue from 'vue'


Vue.use({
  install(Vue) {
    Vue.prototype.$printTag = new PrintTag()
    Vue.$printTag = new PrintTag()
  }
})

export default (_) => { }