<template>
  <v-overlay :value="loading" z-index="9999">
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  computed: {
    loading() {
      return this.$store.state.app.loading;
    },
  },
};
</script>
