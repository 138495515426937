<template>
  <v-dialog v-model="dialog" persistent max-width="1400px" origin="left top">
    <v-card>
      <v-card-title v-text="title" />

      <v-card-text>
        <v-row>
          <v-col class="mr-8">
            <h3 class="mt-2 mb-2">Detalhes</h3>
            <v-row>
              <v-col>
                <company-select v-model="form.company" />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                ><app-text-field label="Descrição" v-model="form.description" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <CustomerSearch v-model="form.customer" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <AccountPlanSearch disabled v-model="form.account_plan" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  v-model="form.details"
                  label="Detalhes"
                  hide-details="auto"
                  dense
                  outlined
                />
              </v-col>
            </v-row>
          </v-col>
          <v-divider vertical />
          <v-col class="ml-8">
            <h3 class="mb-2">Parcelas</h3>
            <BillPayableFormParcels
              v-model="form.parcels"
              :disabledAdd="isCredit"
              :disableDocument="isCredit"
              :disableDueDate="isCredit"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions
        class="justify-end py-4"
        style="background-color: #f5f5f5"
      >
        <v-btn
          @click="dialog = false"
          text
          class="text-capitalize"
          color="secondary"
          >Cancelar</v-btn
        >
        <v-btn @click="handleSave()" class="text-capitalize" color="primary"
          >Salvar</v-btn
        >
        <v-btn
          @click="handleSaveAndContinue()"
          class="text-capitalize"
          color="primary"
          >Salvar e continuar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CompanySelect from "@/components/company/ui/CompanySelect.vue";
import CustomerSearch from "@/components/customer/ui/CustomerSearch.vue";

import AccountPlanSearch from "@/components/account-plan/ui/AccountPlanSearch";
import CostCenterSearch from "@/components/cost-center/ui/CostCenterSearch";
import BillPayableFormParcels from "@/components/bill-payable/forms/BillPayableFormParcels";

export default {
  components: {
    CompanySelect,
    CustomerSearch,
    AccountPlanSearch,
    CostCenterSearch,
    BillPayableFormParcels,
  },

  data() {
    return {
      dialog: false,
      form: null,
      baseForm: {
        type: null,
        company: null,
        customer: null,
        description: null,
        account_plan: {
          id: 1,
          name: "Receita de vendas",
        },

        details: null,
        parcels: [
          {
            document: null,
            value: 0,
            due_date: null,
          },
        ],
      },
    };
  },

  created() {
    this.reset();
  },

  computed: {
    isCredit() {
      return this.form.type == "out";
    },

    title() {
      return this.isCredit ? "Novo Credito" : "Nova Conta a Receber";
    },
  },

  methods: {
    open(type) {
      this.reset();

      this.form.type = type;

      this.dialog = true;
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },

    handleSave() {
      this.store().then(() => {
        this.$emit("store");
        this.dialog = false;
      });
    },

    handleSaveAndContinue() {
      this.store().then(() => {
        this.$emit("store");
        this.reset();
      });
    },

    async store() {
      return new Promise(async (resolve, reject) => {
        this.$store.commit("app/showLoading");
        await this.$http
          .store("bill-receivable/bill-receivable", this.form)
          .then((response) => {
            resolve();
          })
          .catch((error) => {});
        this.$store.commit("app/hideLoading");
      });
    },
  },
};
</script>
