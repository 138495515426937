export default [
  {
    path: '/funcionarios',
    name: 'employeeIndex',
    meta: { layout: 'appLayout', displayName: 'Funcionários', icon: 'mdi-account-circle-outline', permission: 'employeeIndex' },
    component: () => import('@/views/EmployeesIndex')
  },
  {
    path: '/perfis-de-permissao',
    name: 'rolesIndex',
    meta: { layout: 'appLayout', displayName: 'Perfis de Permissão', icon: 'mdi-account-lock', permission: 'rolesIndex' },
    component: () => import('@/views/RolesIndex')
  },

  {
    path: '/empresas',
    name: 'companiesIndex',
    meta: { layout: 'appLayout', displayName: 'Empresas', icon: 'mdi-domain', permission: 'companiesIndex' },
    component: () => import('@/views/CompaniesIndex')
  },
]