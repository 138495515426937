import { format, parseISO, formatRelative } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR';
import numeral from 'numeral'
import "numeral/locales/pt-br";
import extenso_plugin from 'extenso';

export default {

  numeral(value, format = '0 a') {
    if (!value) {
      var value = 0
    }

    numeral.locale('pt-br');

    return numeral(parseFloat(value)).format(format)
  },

  decimal(value, decimalPlaces = 2) {
    if (!value) {
      var value = 0
    }

    numeral.locale('pt-br');

    return numeral(parseFloat(value)).format('0,0.' + "0".repeat(decimalPlaces))

  },


  dateBr(value, dateFormat = 'dd/MM/yyyy') {
    if (value) {
      try {
        return format(parseISO(value), dateFormat, { locale: ptBR })

      } catch (e) {
        return value
      }
    }
  },

  dateRelative(value) {
    if (value) {
      try {
        return formatRelative(parseISO(value), new Date(), { locale: ptBR })
      } catch (e) {
        console.log(e)
      }
    }
  },

  dateTimeBr(value, dateFormat = 'dd/MM/yyyy H:mm:ss') {
    if (value) {
      try {
        return format(parseISO(value), dateFormat)
      } catch (e) {
        return value
      }
    }
  },

  cpfCnpj() {

  },

  cnpj(value) {
    if (value) {
      return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5")
    }
    return ''
  },

  cpf(value) {
    if (value) {
      return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4")
    }
    return ''
  },

  rg(value) {
    if (value) {
      return value.replace(/(\d{2})(\d{3})(\d{3})(\d{1})/g, "\$1.\$2.\$3-\$4")
    }
    return ''
  },

  cep(value) {

    if (value) {
      return value.replace(/(\d{5})(\d{3})/g, "\$1-\$2")
    }
    return ''
  },

  phone(value) {

    if (value) {

      if (value.length == 11) {
        return value.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/g, "(\$1) \$2 \$3-\$4")
      }
      if (value.length == 10) {
        return value.replace(/(\d{2})(\d{4})(\d{4})/g, "(\$1) \$2-\$3")
      }

      return ''

    }

    return null
  },

  extenso(value) {
    if (!value) {
      var value = 0

    }
    return extenso_plugin(this.decimal(value), { mode: 'currency' });
  },

  moneyBr(value) {
    if (!value) {
      var value = 0
    }

    var parsedValue = parseFloat(value)
    var value = parsedValue.toFixed(2).split('.');

    value[0] = value[0].split(/(?=(?:...)*$)/).join('.');

    return 'R$ ' + value.join(',')
    // return value.join(',');
  },



  // função para substituir uma quantidade de caracteres  por * 
  hide(value, { start = null, end = null, }) {
    //substituir o 5 últimos caracteres por *
    if (end) {
      return value.substring(0, value.length - end) + '*'.repeat(end)
    }
    //substituir o 5 primeiros caracteres por *
    if (start) {
      return '*'.repeat(start) + value.substring(start, value.length)
    }

  }

}