<template>
  <apexchart type="line" :options="chartsOptions" :series="series" />
</template>

<script>
import ApexCharts from "apexcharts";

export default {
  components: {
    ApexCharts,
  },

  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      defaultChart: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        tooltip: {
          enabled: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },

        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.0,
          },
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
          ],
        },
      },
    };
  },

  computed: {
    chartsOptions() {
      return {
        ...this.defaultChart,
        ...this.options,
      };

      // return {
      //   chart: {
      //     id: "vuechart-example",
      //   },
      //   zoom: {
      //     enabled: false,
      //   },
      //   ...this.options,
      // };
    },

    series() {
      return this.$attrs.series;
      //this.$attrs.series.find((item) => item.value)
      // return this.data.map((item) => {
      //   console.log(item);
      //   return {
      //     name: "Vue Chart",
      //     data: item.net_total_sum,
      //   };
      // });
      // return this.$attrs.series.map((item) => {
      //   return {
      //     name: item.name,
      //     data: item.data,
      //   };
      // });
      // return [
      //   {
      //     name: "Vue Chart",
      //     data: [30, 40, 45, 50, 49, 60, 70, 81],
      //   },
      // ];
    },

    categories() {
      return [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998];
    },
    options() {
      return this.$attrs.options;
    },
  },
};
</script>

<style>
</style>