<template>
  <div v-if="visible">
    <v-sheet color="#171D34" class="pa-4">
      <b>Nova Versão Disponível</b>
      <div class="mt-2 text-center">
        <v-btn
          @click.stop="refreshApp()"
          class="text-capitalize"
          block
          color="success"
        >
          <app-icon left>autorenew </app-icon> Atualizar
        </v-btn>
      </div>
    </v-sheet>
    <v-divider />
  </div>
</template>

<script>
export default {
  data: () => ({
    refreshing: null,
    registration: null,
    service: null,

    visible: false,
  }),
  created() {
    // traduzir o comentário : Listen for swUpdated event and display refresh snackbar as required.

    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });

    // Refresh all open app tabs when a new service worker is installed.
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.refreshing) return;
        this.refreshing = true;
        window.location.reload();
      });
    }
  },
  methods: {
    showRefreshUI(e) {
      this.registration = e.detail;
      this.service = e;
      this.visible = true;
    },

    refreshApp() {
      this.visible = false;

      if (!this.registration || !this.registration.waiting) {
        return;
      }

      this.registration.waiting.postMessage("skipWaiting");
    },
  },
};
</script>

<style>
</style>