import Vue from 'vue'
import Dexie from 'dexie';

export const db = new Dexie('ERP-CDT');

db.version(1).stores({
  sales: '++id,key,data', // Primary key and indexed props
});


Vue.use({
  install(Vue) {
    Vue.prototype.$db = db
    Vue.$db = db
  }
})

export default (_) => { }