
export default {

  round(value, decimals = 2) {
    return Number(Math.round(parseFloat(value) + 'e' + decimals) + 'e-' + decimals);
  },

  sum(arr, field) {
    return arr.reduce((sum, row) => sum + parseFloat(row[field]), 0);
  },

  divideParcelsValue(value, divisor) {
    
    let parcels = this.round(value / divisor)

    let remainder = value - (parcels * divisor)

    return {
      firstValue : this.round(parcels + remainder),
      value : parcels
    }
  },

  ratio(values, valueToRatio, precision = 2) {
    
    var sum = values.reduce((acc, val) => { 

      return acc + parseFloat(val) }, 0)
    
    var newArr = values.map(row => this.round(row * parseFloat(valueToRatio) / sum, precision))

    return newArr
  },

  interest(value, interestAMonth, days) {

    let interestPerDay = this.interestRatePerMonthToPerDay(interestAMonth)

    return (value * ((1 + interestPerDay / 100) ** days) - value)

  },

  interestRatePerMonthToPerDay(interestPerMonth) {
    return  (((1 + (interestPerMonth / 100)) ** (1 / 30)) - 1 ) * 100
  }

};