export default {

  namespaced: true,

  state: () => ({
    drawer: true,

    alert: {
      message: null,
      id: null,
    },

    loading: false,

    httpError: null,

    snackbar: {},

    typePrint: null,

    isOffline: false,
    shortcutComponent: {}
  }),

  getters: {

    getTypePrint(state, getters) {
      return state.typePrint == null ? localStorage.getItem("typePrint") : state.typePrint;
    },
    appIsOffline: state => {
      return state.isOffline
    },
    shortcutComponent: state => {
      return state.shortcutComponent;
    }
  },

  mutations: {
    alert(state, message) {
      state.alert.message = message
      state.alert.id = Math.random()
    },

    snackbar(state, props) {
      state.snackbar = props
    },

    showLoading(state) {
      state.loading = true;
    },

    hideLoading(state) {
      state.loading = false;
    },

    setHttpError(state, error) {
      state.httpError = error
    },

    resetHttpError(state) {
      state.httpError = null
    },

    toggleDrawer(state) {
      state.drawer = !state.drawer
    },

    setDrawer(state, value) {
      state.drawer = value
    },

    setTypePrint(state, value) {

      localStorage.setItem("typePrint", value);
      state.typePrint = value;

    },

    setIsOffline(state, isOffline) {
      state.isOffline = isOffline
    },

    setShortcutComponent(state, shortcut) {
      state.shortcutComponent = shortcut
    }
  },

}
