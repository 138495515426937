<template>
  <v-dialog v-model="dialog" width="500px">
    <v-card>
      <v-card-title>Juros</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <app-number-field
              v-model="form.interestRate"
              label="Taxa de juros a.m"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <app-number-field
              v-model="form.gracePeriod"
              :precision="0"
              label="Carência (dias)"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn class="text-capitalize" text @click="dialog = false">
          Voltar
        </v-btn>
        <v-btn class="text-capitalize" color="primary" @click="handleStore()">
          Aplicar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      form: {
        interestRate: 0,
        gracePeriod: 0,
      },
    };
  },

  methods: {
    open(interestRate, gracePeriod) {
      this.dialog = true;

      this.form.interestRate = interestRate;
      this.form.gracePeriod = gracePeriod;
    },

    handleStore() {
      this.dialog = false;

      this.$emit("store", this.form);
    },
  },
};
</script>

<style></style>
