<template>
  <v-img v-bind="$attrs" v-on="$listeners" :src="getImage(src)" />
</template>

<script>
import MediaMixin from "@/mixins/Media/MediaMixin";
export default {
  mixins: [MediaMixin],

  props: {
    src: {},
  },
};
</script>

<style></style>
