
<template>
  <v-main style="height:100%">
    <v-container class="pa-0" fluid style="height:100%">
      <slot></slot>
    </v-container>
  </v-main>
</template>

<script>
export default {};
</script>
