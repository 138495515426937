import AppShortcutBtn from '@/components/app/sections/AppShortcutBtn.vue';
import router from '@/router';
import store from '@/store'
import Vue from 'vue';

class Shortcut {


  //TODO: função para abrir o atalho do tipo componente, functionName é o nome da função que será executada no componente
  // abrirComponent() {
  //   this.$shortcut.open("StoreCustomerForm", {
  //     functionName: "open",
  //     customer_id: 1,
  //   });
  // },
  //
  //TODO: função para abrir o atalho do tipo link com query ou params, ambos funcionam da mesma forma e podem ser usados juntos 
  // abrirLink() {
  //   this.$shortcut.open("billReceivableIndex", {
  //     query: {
  //       customer_id: 1,
  //     },
  //     params: {
  //       customer_id: 1,
  //     }
  //   });
  // },

  constructor() {

    //TODO: cria uma lista de atalhos
    this.shortcuts = this.getLinks().concat(this.getComponents());

  }

  //TODO: função para retornar o componente do atalho
  get component() {
    return store.getters['app/shortcutComponent'];
  }

  //TODO: função para listar os atalhos do tipo componente
  getComponents() {

    let rawComponents = Object.keys(AppShortcutBtn.components);

    let components = [];

    rawComponents.forEach(component => {
      components.push({ name: component, type: 'component' })
    });

    return components;

  }

  //TODO: função para listar os atalhos do tipo link
  getLinks() {
    let links = [];

    router.options.routes.forEach(route => {
      if (route.name) {

        links.push({
          name: route.name,
          type: 'link',
          meta: route.meta ? route.meta.permission : null
        });

      }
    });
    return links;
  }


  // TODO: função para abrir o atalho
  open(name, data) {

    let shortcut = this.get(name);

    if (shortcut && shortcut.type == 'link') {
      this.openLink(shortcut, data);
    }

    if (shortcut && shortcut.type == 'component') {


      this.openComponent(shortcut, data);
    }

  }

  //TODO: função responsável por abrir o atalho do tipo link
  openLink(shortcut, data) {
    const routeData = router.resolve({
      name: shortcut.name,
      ...data,
    });
    window.open(routeData.href, "_blank");
  }

  //TODO: função responsável por abrir o atalho do tipo componente
  openComponent(shortcut, params) {
    let data = { ...shortcut, params: params };

    store.commit('app/setShortcutComponent', data);
  }

  //TODO: função responsável por encontrar as informações do atalho na lista de atalhos, a partir do nome do atalho
  get(shortcut_name) {

    //TODO: cria uma lista de atalhos
    var shortcuts = Array(Object.values(this.shortcuts).map(shortcut => {
      return {
        name: shortcut.name,
        type: shortcut.type,
        meta: shortcut.meta,
      }
    }))[0];


    //TODO: procura o atalho na lista de atalhos
    let shortcut = shortcuts.find(shortcut => shortcut.name == shortcut_name);

    //TODO: se o atalho for encontrado, retorna o atalho
    if (shortcut != undefined) {
      return shortcut;
    }

    //TODO: se o atalho não for encontrado, exibe um erro no console
    if (!shortcut) {
      console.error('Atalho não encontrado: ' + shortcut_name);
      return;
    }
  }
}

export default Shortcut