export default {

  namespaced: true,

  state: () => ({
    userData: null,
  }),

  mutations: {
    setUserData(state, userData) {
      state.userData = userData
    },

    removeUserData(state) {
      state.userData = null
    }
  },

  getters: {
    isLoggedIn: state => {
      return (state.userData !== null)
    }
  },
}