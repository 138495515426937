export default [
  {
    path: '/entregas/entrega/imprimir/:id',
    name: 'PrintDelivery',
    meta: {
      layout: 'PrintLayout', displayName: 'Imprimir Romaneio', permissions: 'printDelivery'
    },
    component: () => import('@/views/deliveries/PrintDelivery')
  },
  {
    path: '/entregas',
    name: 'deliveryIndex',
    meta: { layout: 'appLayout', displayName: 'Entregas', icon: 'mdi-truck', permission: 'deliveriesIndex' },
    component: () => import('@/views/deliveries/DeliveriesIndex.vue')
  },
  {
    path: '/entrega/novo/:id',
    name: 'storeDeliveryForm',
    meta: { layout: 'appLayout', displayName: 'Nova Entrega', permission: 'deliveryCreateEdit' },
    component: () => import('@/views/deliveries/StoreDeliveryForm')
  },

  {
    path: '/vendas/visualizar-entrega/',
    redirect: '/entregas',
  },
]