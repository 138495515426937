import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import pt from 'vuetify/lib/locale/pt';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
  theme: {
    //dark: true,
    themes: {
      light: {
        primary: '#3C3ED2',
        success : '8bc34a',
        backgroud : "#f5f5f5"
        // secondary: '#b0bec5',
        // accent: '#8c9eff',
        // error: '#b71c1c',
      },
      dark : {
        backgroud : "#fff"
      }
    },
  },
  lang: {
    locales: { pt },
    current: 'pt',
  },
});
