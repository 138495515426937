
export default {

  namespaced: true,

  state: () => ({

    product: {},
    productIndex: null

  }),

  getters: {},

  mutations: {

    setProduct(state, { product, productIndex }) {
      state.product = product
      state.productIndex = productIndex
    }

  },

  actions: {}

}
