<template>
  <b :class="[canceled ? 'text-decoration-line-through' : '', color]">{{
    $format.decimal(value)
  }}</b>
</template>

<script>
export default {
  props: {
    value: {},
    canceled: {
      default: false,
    },
  },

  computed: {
    isNegative() {
      return this.value < 0;
    },

    color () {
      if (this.canceled) {
        return 'text--disabled'
      }

      if (this.isNegative) {
        return 'error--text'
      } 
      
      return 'success--text'
    }
  },
};
</script>

<style>
</style>