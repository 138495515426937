export default [
  {
    path: '/contas',
    name: 'accountTransactionIndex',
    meta: { layout: 'appLayout', displayName: 'Contas', icon: 'mdi-currency-usd', permission: 'accountTransactionIndex' },
    component: () => import('@/views/finances/AccountTransactionsIndex')
  },
  {
    path: '/contas/importar-extrato',
    name: 'BankStatementImportIndex',
    meta: { layout: 'appLayout', displayName: 'Importação de Extrato', permission: 'accountTransactionIndex' },
    component: () => import('@/views/finances/BankStatementImportIndex')
  },
  {
    path: '/contas/recibo-imprimir/:id',
    name: 'printReceiptIndex',
    meta: { layout: 'PrintLayout', displayName: 'Imprimir Recibo', },
    component: () => import('@/views/finances/PrintReceiptIndex')
  },
  {
    path: '/contas/vale-credito-imprimir/:id',
    name: 'PrintCreditReceiptIndex',
    meta: { layout: 'PrintLayout', displayName: 'Imprimir Vale Crédito', permission: 'printCreditReceiptIndex' },
    component: () => import('@/views/finances/PrintCreditReceiptIndex')
  },
  {
    path: '/contas/relatorio/:id',
    name: 'accountTransactionReport',
    meta: { layout: 'printLayout', displayName: 'Relatório de Contas', icon: 'mdi-currency-usd', permission: 'accountTransactionIndex' },
    component: () => import('@/views/finances/AccountTransactionsReport')
  },
  {
    path: '/fluxo-de-caixa/dre',
    name: 'cashFlowResultReport',
    meta: { layout: 'appLayout', displayName: 'Demonstrativo de resultado', icon: 'mdi-currency-usd', permission: 'cashFlowResultReport' },
    component: () => import('@/views/finances/CashFlowResultReport')
  },
  {
    path: '/gerenciar-contas',
    name: 'manageAccountsIndex',
    meta: { layout: 'appLayout', displayName: 'Gerenciar Contas', icon: 'mdi-currency-usd', permission: 'super_admin' },
    component: () => import('@/views/finances/ManageAccountsIndex')
  },
  {
    path: '/contas-a-pagar',
    name: 'billPayableIndex',
    meta: { layout: 'appLayout', displayName: 'Contas A Pagar', icon: 'mdi-currency-usd', permission: 'billPayableIndex' },
    component: () => import('@/views/finances/BillPayableIndex')
  },
  {
    path: '/contas-a-receber',
    name: 'billReceivableIndex',
    meta: { layout: 'appLayout', displayName: 'Contas A Receber', icon: 'mdi-currency-usd', permission: 'billReceivableIndex' },
    component: () => import('@/views/finances/BillReceivableIndex')
  },
  {
    path: '/contas-a-receber/relatorio-por-cliente',
    name: 'BillReceivableReportByCustomer',
    meta: { layout: 'appLayout', displayName: 'Relatório Contas A Receber por Cliente ', icon: 'mdi-currency-usd', permission: 'billReceivableIndex' },
    component: () => import('@/views/finances/BillReceivableReportByCustomer')
  },
  {
    path: '/contas-a-pagar/relatorio',
    name: 'billPayableReport',
    meta: { layout: 'appLayout', displayName: 'Relatório Contas A Pagar' },
    component: () => import('@/views/finances/BillPayableReport')
  },
  {
    path: '/fluxo-de-caixa',
    name: 'cashFlowIndex',
    meta: { layout: 'appLayout', displayName: 'Fluxo de caixa', icon: 'mdi-chart-box-outline', permission: 'cashFlowIndex' },
    component: () => import('@/views/finances/CashFlowIndex')
  },
  {
    path: '/contas-a-receber/cliente/:customer_id/extrato-de-debitos/:bill_receivables_ids',
    name: 'CustomerDebitStatementIndex',
    meta: { layout: 'printLayout', displayName: 'Extrato de Débitos', permission: 'customerDebtStatement' },
    component: () => import('@/views/finances/CustomerDebitStatement.vue')
  },

]