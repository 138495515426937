
const noAvatar = 'account-circle.svg'


export default {
  methods: {
    getImage(image) {
      return image == null ? `${process.env.BASE_URL}${noAvatar}` : `${process.env.VUE_APP_IMAGES_BASE_URL}${image.id}/${image.file_name}`
    }
  }
}