<template>
  <v-dialog v-model="dialog" persistent max-width="1400px" origin="left top">
    <v-card>
      <v-card-title> Renegociar Contas </v-card-title>

      <v-card-text>
        <v-row>
          <v-col class="mr-8">
            <h3 class="my-2">
              Cliente: {{ customer.name }} #{{ customer.code }}
            </h3>
            <h3 class="my-2">Detalhes</h3>
            <v-row>
              <v-col class="d-flex justify-space-between">
                <h4>Valor Original: {{ $format.decimal(sum_value) }}</h4>

                <h4>Valor Pago: {{ $format.decimal(sum_paid_value) }}</h4>

                <h4>
                  Saldo Dev.:
                  {{ $format.decimal(sum_value - sum_paid_value) }}
                  <!-- {{ $format.decimal(sum_net_paid_value - sum_value) }} -->
                </h4>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <app-text-field v-model="form.motive" label="Motivo" />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-textarea
                  v-model="form.notes"
                  label="Detalhes"
                  hide-details="auto"
                  dense
                  outlined
                />
              </v-col>
            </v-row>
          </v-col>
          <v-divider vertical />
          <v-col class="ml-8">
            <h3 class="mb-2">Parcelas</h3>
            <BillPayableFormParcels
              v-model="form.parcels"
              :showDocument="false"
              doc-prefix="RN"
            />
            <div class="mt-12 mr-12" v-if="form.parcels.length > 1">
              <v-divider />
              <div class="my-4 d-flex justify-space-between">
                <h3>Total:</h3>
                <h3>{{ $format.moneyBr(sum_parcels_total_value) }}</h3>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-end py-4">
        <v-btn
          @click="dialog = false"
          text
          class="text-capitalize"
          color="secondary"
          >Cancelar</v-btn
        >
        <v-btn @click="handleStore()" class="text-capitalize" color="primary"
          >Salvar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CompanySelect from "@/components/company/ui/CompanySelect.vue";
import CustomerSearch from "@/components/customer/ui/CustomerSearch.vue";

import AccountPlanSearch from "@/components/account-plan/ui/AccountPlanSearch";
import CostCenterSearch from "@/components/cost-center/ui/CostCenterSearch";
import BillPayableFormParcels from "@/components/bill-payable/forms/BillPayableFormParcels";

export default {
  components: {
    CompanySelect,
    CustomerSearch,
    AccountPlanSearch,
    CostCenterSearch,
    BillPayableFormParcels,
  },
  data() {
    return {
      dialog: false,
      form: {},
      baseForm: {
        notes: null,
        motive: null,
        billReceivables: [{ customer: {} }],
        parcels: [
          {
            document: "RN 1",
            value: 0,
            due_date: null,
          },
        ],
      },
      bills: [],
    };
  },
  created() {
    this.reset();
  },

  computed: {
    // sum_net_paid_value() {
    //   return this.form.billReceivables.reduce(
    //     (sum, row) => sum + row.net_paid_value,
    //     0
    //   );
    // },
    sum_value() {
      return this.$calc.sum(this.form.billReceivables, "value");
    },
    sum_paid_value() {
      return this.$calc.sum(this.form.billReceivables, "paid_value");
    },
    sum_parcels_total_value() {
      return this.$calc.sum(this.form.parcels, "value");
    },
    customer() {
      return this.form.billReceivables[0].customer;
    },
  },
  methods: {
    open(billReceivables) {
      this.reset();

      this.form.billReceivables = billReceivables;
      this.dialog = true;
    },
    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },
    handleStore() {
      this.$loading.start();
      this.$http
        .store("bill-receivable/bill-receivable-negotiation", this.form)
        .then((response) => {
          this.$loading.finish();
          this.dialog = false;
          this.$emit("store");
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
  },
};
</script>

<style>
</style>