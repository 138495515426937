<template>
  <div>
    <app-mask-field
      v-bind="$attrs"
      hide-details="auto"
      v-on="$listeners"
      dense
      append-outer-icon="mdi-magnify"
      label="CEP"
      @input="handleInput($event)"
      :loading="loading"
      mask="#####-###"
      @click:append-outer="handleCitySearch()"
    />
    <SearchCity
      ref="SearchCity"
      @input="handleInput($event)"
      :item-value="(val) => val.postal_code"
    />
  </div>
</template>

<script>
import axios from "axios";
import SearchCity from "@/components/app/forms/SearchCity";

export default {
  components: {
    SearchCity,
  },
  data: () => ({
    loading: false,
    cep: "",
  }),

  methods: {
    handleInput(cep) {
      cep = cep.replace(/\D/g, "");

      if (cep.length == 8) {
        this.searchCep(cep);
      }
    },

    async searchCep(cep) {
      this.loading = true;

      await axios
        .get(`https://viacep.com.br/ws/${cep}/json/`)
        .then((response) => {
          this.$emit("address", response.data);
        });

      this.loading = false;
    },
    handleCitySearch() {
      this.$refs.SearchCity.open();
    },
  },
};
</script>