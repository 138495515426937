<template>
  <v-chip small v-bind="$attrs">{{ company.short_name }}</v-chip>
</template>

<script>
export default {
  props: {
    company: {},
  },
};
</script>

<style>
</style>