<template>
  <v-icon v-bind="$attrs" v-on="$listeners" class="material-symbols-rounded">
    <slot />
  </v-icon>
</template>

<script>
export default {};
</script>

<style>
</style>