<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="text-h5">Perfil do Usuário</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col class="d-flex justify-center">
            <AvatarForm
              :uploadUrl="uploadUrl"
              :associate="user"
              :media="employeeMedia.media[0]"
              @store="getLoggedUser()"
            />
          </v-col>
        </v-row>
        <h3 class="mt-10 mb-5">Alterar senha</h3>
        <v-row>
          <v-col cols="12">
            <app-password-field
              v-model="form.oldPassword"
              label="Senha atual"
            />
          </v-col>
          <v-col cols="12">
            <app-password-field v-model="form.newPassword" label="Nova Senha" />
          </v-col>
          <v-col cols="12">
            <app-password-field
              v-model="form.newPassword_confirmation"
              label="Confirme sua senha"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions style="background-color: #f5f5f5">
        <v-spacer></v-spacer>
        <v-btn @click="store()" class="text-capitalize mr-2" color="primary"
          >Salvar</v-btn
        >
        <v-btn @click="dialog = false" text class="text-capitalize"
          >Cancelar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AvatarForm from "@/components/app/forms/AvatarForm";

export default {
  components: { AvatarForm },

  data: () => ({
    uploadUrl: "employee/avatar",

    dialog: false,
    form: {},
    baseForm: {
      oldPassword: null,
      newPassword: null,
      newPassword_confirmation: null,
      user_id: null,
    },
  }),

  created() {
    this.reset();
  },
  computed: {
    user() {
      return this.$store.state.auth.userData.user;
    },
    employeeMedia() {
      return this.$store.state.auth.userData.employee;
    },
  },
  methods: {
    async open() {
      await this.reset();
      this.dialog = true;
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },

    store() {
      this.form.user_id = this.user.id;
      this.$http
        .store("employee/update-password", this.form)
        .then((response) => {
          // this.dialog = false;
        })
        .catch((error) => {});
    },

    getLoggedUser() {
      this.$auth.userData();
    },
  },
};
</script>
