<template>
  <v-btn
    :outlined="!confirmState"
    color="error"
    class="text-capitalize mr-auto"
    @click="handleCancel()"
    v-bind="$attrs"
  >
    {{ text }}
  </v-btn>
</template>

<script>
export default {
  data: () => ({
    confirmState: false,
  }),

  computed: {
    text() {
      return this.confirmState ? "Tem certeza ?" : "Cancelar";
    },
  },

  watch: {
    confirmState(val) {
      if (val == true) {
        setTimeout(() => (this.confirmState = false), 2000);
      }
    },
  },

  methods: {
    handleCancel() {
      if (this.confirmState) {
        this.$emit("click");
        this.reset();
      } else {
        this.confirmState = true;
      }
    },

    reset() {
      this.confirmState = false;
    },
  },
};
</script>

<style>
</style>