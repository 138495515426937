
import Vue from 'vue'

// ui
Vue.component('app-cancel-btn', require('@/components/app/ui/AppCancelBtn.vue').default)
Vue.component('app-text-field', require('@/components/app/ui/AppTextField.vue').default)
Vue.component('app-password-field', require('@/components/app/ui/AppPasswordField.vue').default)
Vue.component('app-number-field', require('@/components/app/ui/AppNumberField.vue').default)
Vue.component('app-date-field', require('@/components/app/ui/AppDateField.vue').default)
Vue.component('app-code-field', require('@/components/app/ui/AppCodeField.vue').default)
Vue.component('app-text-search-field', require('@/components/app/ui/AppTextSearchField.vue').default)
Vue.component('app-cnpj-field', require('@/components/app/ui/AppCnpjField.vue').default)
Vue.component('app-select', require('@/components/app/ui/AppSelect.vue').default)
Vue.component('app-status-switch', require('@/components/app/ui/AppStatusSwitch.vue').default)
Vue.component('app-cep-field', require('@/components/app/ui/AppCepField.vue').default)
Vue.component('app-mask-field', require('@/components/app/ui/AppMaskField.vue').default)
Vue.component('app-cpf-field', require('@/components/app/ui/AppCpfField.vue').default)
Vue.component('app-rg-field', require('@/components/app/ui/AppRgField.vue').default)
Vue.component('app-payment-method-select', require('@/components/app/ui/AppPaymentMethodSelect.vue').default)
Vue.component('app-payment-method-chip', require('@/components/app/sections/AppPaymentMethodChip.vue').default)
Vue.component('app-file-field', require('@/components/app/ui/AppFileField.vue').default)

// sectionns
Vue.component('app-icon', require('@/components/app/sections/AppIcon.vue').default)
Vue.component('app-select-unities', require('@/components/app/sections/AppSelectUnities.vue').default)
Vue.component('app-money-label', require('@/components/app/sections/AppMoneyLabel.vue').default)
Vue.component('app-status-chip', require('@/components/app/sections/AppStatusChip.vue').default)
Vue.component('status-chip', require('@/components/app/sections/StatusChip.vue').default)
Vue.component('app-due-date-label', require('@/components/app/sections/AppDueDateLabel.vue').default)
Vue.component('app-loading', require('@/components/app/sections/AppLoading.vue').default)
Vue.component('app-pagination', require('@/components/app/sections/AppPagination.vue').default)
Vue.component('app-activities', require('@/components/app/sections/AppActivities.vue').default)
Vue.component('error-handler', require('@/components/app/sections/ErrorHandler.vue').default)
Vue.component('app-charts', require('@/components/app/sections/AppCharts.vue').default)
Vue.component('app-card-dashboard', require('@/components/app/sections/AppCardDashboard.vue').default)


//TODO: o AppShortcutBtn é um componente que será usado para abrir e renderizar os atalhos
Vue.component('app-shortcut-btn', require('@/components/app/sections/AppShortcutBtn.vue').default)

export default (_) => { }