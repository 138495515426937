export default [

  {
    path: '/profissionais',
    name: 'professionalIndex',
    meta: { layout: 'appLayout', displayName: 'Profissionais', icon: 'mdi-account-hard-hat', permission: 'professionalIndex' },
    component: () => import('@/views/ProfessionalIndex.vue')
  },
]

