<template>
  <v-dialog width="800px" v-model="dialog" persistent>
    <v-card>
      <v-card-title primary-title>
        <h3 class="headline mb-0">Novo Credito</h3>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col>
            <CustomerSearch v-model="form.customer" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <CompanySelect v-model="form.company" />
          </v-col>
          <v-col>
            <AccountPlanSearch disabled v-model="form.account_plan" />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <app-text-field v-model="form.description" label="Descrição" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <app-number-field v-model="form.value" label="Valor" />
          </v-col>
          <v-col>
            <app-payment-method-select
              label="Método de Pagamento"
              v-model="form.payment_method"
              hide-details="auto"
              :exclude="['bill']"
            />
          </v-col>
        </v-row>
        <v-row class="d-flex justify-end">
          <v-col cols="6">
            <AccountSelect
              :allowed-only="true"
              v-model="form.account"
              label="Selecionar caixa"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="dialog = false"
          color="secondary"
          class="text-capitalize"
          text
        >
          Cancelar
        </v-btn>
        <v-btn @click="handleSave()" class="text-capitalize" color="primary">
          Pagar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CompanySelect from "@/components/company/ui/CompanySelect.vue";
import CustomerSearch from "@/components/customer/ui/CustomerSearch.vue";
import AccountPlanSearch from "@/components/account-plan/ui/AccountPlanSearch";
import CostCenterSearch from "@/components/cost-center/ui/CostCenterSearch";
import BillPayableFormParcels from "@/components/bill-payable/forms/BillPayableFormParcels";
import AccountSelect from "@/components/account/ui/AccountSelect.vue";

export default {
  components: {
    CompanySelect,
    CustomerSearch,
    AccountPlanSearch,
    CostCenterSearch,
    BillPayableFormParcels,
    AccountSelect,
  },
  data: () => ({
    dialog: false,

    form: {},

    baseForm: {
      customer: null,
      company: null,
      value: 0,
      payment_method: null,
      account: null,

      account_plan: {
        id: 1,
        name: "Receita de vendas",
      },
    },
  }),

  methods: {
    open() {
      this.resetForm();

      this.dialog = true;
    },

    resetForm() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },

    handleSave() {
      this.$loading.start();
      this.$http
        .store("bill-receivable/credit", this.form)
        .then((response) => {
          this.$loading.finish();
          this.$emit("store");
          this.dialog = false;
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
  },
};
</script>

<style>
</style>