<template>
  <div class="d-flex align">
    <div>
     <AccountIcon :account="account" class="d-inline mr-2" />
    </div>
    <div>
      <span>{{ account.name }}</span>
      <!-- <br />
      <small v-if="account.type == 'bank'" class="text--secondary ml-2"
        >CC: {{ account.bank_agency }} | Ag: {{ account.bank_account }}
      </small> -->
    </div>
  </div>
</template>

<script>
import AccountIcon from "@/components/account/sections/AccountIcon";

export default {
  components: { AccountIcon },
  props: {
    account: {
      default: {},
    },
  },
};
</script>

<style>
</style>