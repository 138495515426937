<template>
  <v-card outlined v-bind="$attrs">
    <v-card-title class="d-flex justify-space-between">
      <slot name="title">
        <span>{{ title }}</span>
      </slot>

      <slot name="icon" />
      <v-avatar :color="iconProps.color">
        <v-icon dark v-text="iconProps.icon" v-if="iconProps.font == 'mdi'" />
        <app-icon dark v-text="iconProps.icon" v-if="iconProps.font == 'app'" />
      </v-avatar>
    </v-card-title>
    <v-card-subtitle>
      <slot name="subtitle" />
    </v-card-subtitle>
    <v-card-text>
      <slot name="text" />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Title",
    },
  },

  computed: {
    iconProps() {
      return {
        icon: this.$attrs.icon || "mdi-note",
        color: this.$attrs.iconColor || "success",
        font: this.$attrs.iconFont || "mdi",
      };
    },
  },
};
</script>

<style>
</style>