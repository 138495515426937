import printUrls from "../enums/printUrls"


export default {
  preview(params) {

    const url = printUrls.find(item => item.origin === params.origin).url + params.origin_id;
    const popupWin = window.open(
      url,
      "_blank",
      "width=1920,height=1800,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no"
    );
    popupWin.window.focus();

  },

  customPreview(url) {

    const popupWin = window.open(
      url,
      "_blank",
      "width=1920,height=1800,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no"
    );
    popupWin.window.focus();
  }


}  
