<template>
  <v-select
    item-text="name"
    item-value="id"
    :items="searchResults"
    label="Unidade de Medida"
    dense
    :value="selectedValue"
    hide-details="auto"
    @input="setSelectedValue($event)"
    return-object
  >
    >
    <template v-slot:item="{ item, attrs, on }">
      <v-list-item v-bind="attrs" v-on="on">
        {{ item.symbol }} |  {{ item.name }}
      </v-list-item>
    </template>
    <template v-slot:selection="{ item }">
      {{ item.symbol }} |  {{ item.name }}
    </template>
  </v-select>
</template>
<script>
export default {
  props: {
    value: {
      type: Object,
      default() {
        return {
          id: 12,
          code: 12,
          name: "Unidade",
          symbol: "UN",
          status: 1,
        };
      },
    },
  },

  data() {
    return {
      searchResults: [],

      selectedValue: {},
    };
  },
  watch: {
    value() {
      this.selectedValue = this.value;
    },
  },

  created() {
    this.selectedValue = this.value;
  },
  mounted() {
    this.search();
  },

  methods: {
    async search() {
      await this.$http.index("product/unity").then((response) => {
        this.searchResults = response.unities;
      });
    },
    setSelectedValue(selectedValue) {
      this.selectedValue = selectedValue;

      this.$emit("input", selectedValue);
    },
  },
};
</script>