<template>
  <app-select
    v-bind="$attrs"
    v-on="$listeners"
    :items="paymentMethodsOptions"
    item-text="label"
    item-value="value"
  />
</template>

<script>
import PaymentMethods from "@/enums/paymentMethods";

export default {
  props: {
    exclude: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    paymentMethodsOptions() {
      return PaymentMethods.filter(
        (item) => !this.exclude.includes(item.value)
      );
    },
  },
};
</script>
