<template>
  <v-snackbar
    :value="isVisible"
    @input="onSnackbarInput($event)"
    bottom
    v-bind="props"
  >
    <h4 class="d-inline" v-text="alert.message" />
    <template v-if="props != null && props.showClose" v-slot:action>
      <v-btn dark icon @click="isVisible = false">
        <app-icon>close</app-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data: () => ({
    isVisible: false,
  }),

  computed: {
    alert() {
      return this.$store.state.app.snackbar;
    },
    props() {
      return this.alert.props;
    },
  },

  watch: {
    alert: {
      deep: true,
      handler() {
        this.isVisible = true;
      },
    },
  }, // v-bind="$attrs"

  methods: {
    onSnackbarInput() {
      this.isVisible = false;
    },
  },
};
</script>

<style>
</style>