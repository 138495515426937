import store from '@/store'
import Vue from 'vue';

Vue.use({
  install(Vue) {
    Vue.prototype.$loading = {

      start() {
        store.commit('app/showLoading', true)

      },

      finish() {
        store.commit('app/hideLoading', false)
      }
    }
  }
})

export default (_) => { }