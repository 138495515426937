import Vue from "vue"

export default {

  namespaced: true,

  state: () => ({
    accounts: [],
  }),

  getters: {
    allowedAccounts(state, getters, rootState, rootGetters) {
      return state.accounts.filter((account) => {
        
        // if (account.status == '0') {
        //   return false
        // }

        // // if (account.free_access == '1') {
        // //   return true
        // // }

        let userArr = account.employees.filter((employee) => employee.id == rootState.auth.userData.user.id);

        if (userArr.length > 0) {
          return true
        }

        return false
      });
    }
  },

  mutations: {
    setAccounts(state, accounts) {
      state.accounts = accounts
    },

  },

  actions: {
    async index({ commit }) {
      await Vue.$http.index('/account/account').then((response) => {
        commit('setAccounts', response.accounts);
      });
    }
  }

}
