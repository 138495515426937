<template>
  <div>
    <AppAlertOffline />
    <v-app-bar app flat>
      <v-toolbar-title>
        <h3 class="d-inline">{{ $route.meta.displayName }}</h3>
        <v-chip class="ml-2" v-if="showDevelopmentWarningChip" color="error">
          Ambiente de teste
        </v-chip>
      </v-toolbar-title>
      <br />
    </v-app-bar>

    <v-main color="background">
      <v-container fluid>
        <slot />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import AppAlertOffline from "@/components/app/sections/AppAlertOffline";

export default {
  components: { AppAlertOffline },

  computed: {
    showDevelopmentWarningChip() {
      return this.$store.state.auth.userData.metadata.appEnv == "development";
    },
  },
  methods: {},
};
</script>
<style lang="scss"  >
</style>