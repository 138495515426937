
import App from '../api/App'
import Vue from 'vue';

Vue.use({
  install(Vue) {
    Vue.prototype.$app = new App()
    Vue.$app = new App();
  }
})

export default (_) => { }