<template>
   <v-badge :color="colorDueDate" dot inline>
    {{ $format.dateBr(dueDate) }} <span v-if="showDiffDays" class="mx-1 text--secondary">({{ differenceInDays}})</span>
  </v-badge>
</template>

<script>

import { compareAsc, differenceInDays,  parseISO } from 'date-fns'

export default {
  props: {
    dueDate: {},
    showDiffDays : {
      type: Boolean,
      default: false
    }
  },

  computed : {
    differenceInDays() {
      return differenceInDays(new Date(), parseISO(this.dueDate))
    },

    colorDueDate() {
      return this.color[compareAsc(parseISO(this.dueDate), new Date())]
    }
  },

  data : () => ({
    color : {
      '-1' : 'error',
      '0' : 'secondary',
      '1' : 'primary',
    }
  })
  
};
</script>

<style>
</style>