export default [
  // dashboard admin
  {
    path: '/dashboard/admin',
    name: 'adminDashboard',
    component: () => import('@/views/dashboard/AdminDashboard'),
    meta: { layout: 'appLayout', displayName: 'Admin', icon: 'mdi-finance', permission: 'adminDashboardIndex' },
  },
  {
    path: '/dashboard/vendedor',
    name: 'salesmanDashboard',
    component: () => import('@/views/dashboard/SalesmanDashboard'),
    meta: { layout: 'appLayout', displayName: 'Vendedor', icon: 'mdi-finance', },
  },
  {
    path: '/dashboard/vendas',
    name: 'salesDashboard',
    component: () => import('@/views/dashboard/SalesDashboard'),
    meta: { layout: 'appLayout', displayName: 'Vendas', icon: 'mdi-finance', permission: 'saleViewDashboardIndex' },
  },
  {
    path: '/dashboard/compras',
    name: 'purchaseDashboard',
    component: () => import('@/views/dashboard/PurchaseDashboard'),
    meta: { layout: 'appLayout', displayName: 'Compras', icon: 'mdi-finance', permission: 'purchaseDashboard' },
  },
  {
    path: '/dashboard/contas-a-receber',
    name: 'billReceivableDashboard',
    component: () => import('@/views/dashboard/BillReceivableDashboard'),
    meta: { layout: 'appLayout', displayName: 'Contas A Receber', icon: 'mdi-finance', permission: 'canViewBillReceivableDashboard' },
  },
  {
    path: '/dashboard/produtos',
    name: 'productsDashboard',
    component: () => import('@/views/dashboard/ProductsDashboard'),
    meta: { layout: 'appLayout', displayName: 'Produtos' },
  }
]