
import print from './../utils/print'
import Vue from 'vue'


Vue.use({
  install(Vue) {
    Vue.prototype.$print = print
    Vue.$print = print
  }
})

export default (_) => { }