import Vue from 'vue';

export default async function authMiddleware(to, from, next) {

  if (!to.meta.permission) {
    return true;
  }

  if (!Vue.$acl.can(to.meta.permission)) {
    next('/')
  }

  next()
}