<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    scrollable
    :fullscreen="$app.isMobile"
  >
    <v-card>
      <v-card-title primary-title> Pesquisar Cidade </v-card-title>

      <v-card-text>
        <v-row>
          <v-col>
            <br />
            <app-text-field
              append-icon="mdi-magnify"
              label="Pesquisar"
              v-model="searchParams.text"
              v-debounce:500ms="handleSearchText"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              hide-default-footer
              disable-sort
              dense
              :headers="headers"
              :items="cities.data"
              :items-per-page="cities.per_page"
              @click:row="selectCity($event)"
            >
              <template v-slot:[`item.city`]="{ item }">
                {{ item.city }}
              </template>
              <template v-slot:[`item.postal_code`]="{ item }">
                {{ $format.cep(item.postal_code) }}
              </template>
              <template v-slot:[`item.state`]="{ item }">
                {{ item.state }}
              </template>
            </v-data-table>

            <app-pagination @click="select($event)" :data="cities" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          @click="dialog = false"
          class="text-capitalize"
          text
          color="secondary"
        >
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    itemValue: {
      default() {
        return (val) => val.cep;
      },
    },
  },

  data() {
    return {
      dialog: false,
      headers: [
        { text: "Cidade", value: "city", align: "start" },
        { text: "Estado", value: "state", align: "center" },
        { text: "Cep", value: "postal_code", align: "center" },
      ],

      cities: [],
      searchParams: {},
    };
  },

  methods: {
    open() {
      this.dialog = true;
      this.searchParams = {};
      this.select();
    },

    async select(page) {
      this.searchParams.page = page;
      await this.$http
        .index("tools/city", this.searchParams)
        .then((response) => {
          this.cities = response.cities;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    handleSearchText() {
      this.select();
    },
    selectCity(city) {
      this.$emit("input", this.itemValue(city));
      this.dialog = false;
    },
  },
};
</script>

<style>
</style>