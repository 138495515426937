<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="1600px"
    origin="left top"
    class="pa-md-8"
  >
    <v-card>
      <v-card-title>Receber Contas</v-card-title>
      <v-card-text>
        <h3 class="error--text mt-6">Débitos</h3>
        <v-row>
          <v-col class="pr-8" cols="8">
            <template
              v-for="(row, index) in form.billReceivables.filter(
                (row) => row.type == 'in'
              )"
            >
              <v-row class="mt-4 align-center" :key="index">
                <v-col md="4">
                  <b class="mr-2">
                    {{ row.bill.description }}
                    <v-icon
                      style="font-size: 14px"
                      v-if="row.bill.sale_id && row.bill.sale.has_negotiation"
                      right
                    >
                      mdi-information
                    </v-icon>
                  </b>
                  |
                  <span>Documento: {{ row.bill.document }}</span>
                  <br />
                  <small>{{ $format.dateBr(row.bill.due_date) }}</small>
                </v-col>
                <v-col class="d-flex" md="8">
                  <app-number-field
                    label="Saldo Devedor"
                    :value="row.remainder_value"
                    readonly
                    background-color="#f5f5f5"
                  />
                  <app-number-field label="Pagar" v-model="row.add_payment" />
                  <app-number-field
                    label="Desconto"
                    v-model="row.add_discount"
                  />
                  <app-number-field
                    label="Acréscimo"
                    v-model="row.add_addition"
                  />
                  <app-number-field label="Juros" v-model="row.add_interest" />
                  <app-number-field
                    label="Valor Final"
                    :value="calcNetPaidValue(row)"
                    readonly
                  />
                </v-col>
              </v-row>
              <v-divider :key="'divider' + index" />
            </template>
            <template v-if="hasCredits">
              <h3 class="success--text mt-6">Créditos</h3>
              <template
                v-for="(credit, index) in form.billReceivables.filter(
                  (row) => row.type == 'out' && row.bill.origin == 'custom'
                )"
              >
                <v-row class="mt-2 d-flex" :key="'credit' + index">
                  <v-col md="4">
                    <b>{{ credit.bill.description }}</b>
                  </v-col>

                  <v-col class="d-flex" md="8">
                    <app-number-field
                      label="Saldo  "
                      :value="credit.remainder_value"
                      readonly
                      background-color="#f5f5f5"
                    />
                    <app-number-field
                      label="Utilizar"
                      v-model="credit.add_payment"
                    />
                    <app-number-field
                      label="Desconto"
                      v-model="credit.add_discount"
                    />
                    <app-number-field
                      label="Acréscimo"
                      v-model="credit.add_addition"
                    />
                    <app-number-field
                      label="Juros"
                      v-model="credit.add_interest"
                    />
                    <app-number-field
                      label="Valor Final"
                      :value="calcNetPaidValue(credit)"
                      readonly
                    />
                  </v-col>
                  <!-- <v-col class="d-flex ml-auto" md="4">
                    <app-number-field
                      label="Saldo"
                      :value="credit.remainder_value"
                      readonly
                      background-color="#f5f5f5"
                    />
                    <app-number-field
                      label="Utilizar"
                      :value="credit.add_payment"
                    />
                  </v-col> -->
                </v-row>
                <v-divider :key="'divider' + index + 'c'" />
              </template>
            </template>
            <template v-if="hasReturns">
              <h3 class="success--text mt-6">Devoluções</h3>
              <template
                v-for="(return_sale, index) in form.billReceivables.filter(
                  (row) => row.type == 'out' && row.bill.origin == 'sale'
                )"
              >
                <v-row class="mt-2 d-flex" :key="'return_sale' + index">
                  <v-col md="4">
                    <b>{{ return_sale.bill.description }}</b>
                  </v-col>

                  <v-col class="d-flex" md="8">
                    <app-number-field
                      label="Saldo  "
                      :value="return_sale.remainder_value"
                      readonly
                      background-color="#f5f5f5"
                    />
                    <app-number-field
                      label="Utilizar"
                      v-model="return_sale.add_payment"
                    />
                    <app-number-field
                      label="Desconto"
                      v-model="return_sale.add_discount"
                    />
                    <app-number-field
                      label="Acréscimo"
                      v-model="return_sale.add_addition"
                    />
                    <app-number-field
                      label="Juros"
                      v-model="return_sale.add_interest"
                    />
                    <app-number-field
                      label="Valor Final"
                      :value="calcNetPaidValue(return_sale)"
                      readonly
                    />
                  </v-col>
                </v-row>
                <v-divider :key="'divider' + index + 'return_sale'" />
              </template>
            </template>
          </v-col>
          <v-col class="pl-8" cols="4">
            <v-card color="grey lighten-3 " elevation="0">
              <v-card-text>
                <div
                  class="dashed-bottom-border pb-1 mb-4 d-flex justify-space-between"
                >
                  <div>
                    <v-icon class="mr-4">
                      mdi-currency-usd-circle-outline
                    </v-icon>
                    Pagar
                    <v-btn
                      class="text-capitalize ml-1"
                      small
                      text
                      color="info"
                      @click="handleCalcPayment()"
                    >
                      Calcular
                    </v-btn>
                  </div>
                  <div>
                    <b>{{ $format.decimal(sum_add_payment) }}</b>
                  </div>
                </div>
                <div
                  class="dashed-bottom-border pb-1 mb-4 d-flex justify-space-between"
                  style="cursor: pointer"
                >
                  <div>
                    <v-icon class="mr-4">mdi-brightness-percent</v-icon>
                    Desconto
                  </div>
                  <div>
                    <b>{{ $format.decimal(sum_add_discount) }}</b>
                  </div>
                </div>
                <div class="text-right"></div>
                <div
                  class="dashed-bottom-border pb-1 mb-4 d-flex justify-space-between"
                  style="cursor: pointer"
                >
                  <div>
                    <v-icon class="mr-4">mdi-percent-outline</v-icon>
                    Juros
                    <v-btn
                      class="text-capitalize ml-1"
                      small
                      text
                      color="info"
                      @click="handleAddInterest()"
                    >
                      Editar
                    </v-btn>
                  </div>
                  <div>
                    <b>{{ $format.decimal(sum_add_interest) }}</b>
                  </div>
                </div>
                <div
                  class="dashed-bottom-border pb-1 mb-4 d-flex justify-space-between"
                >
                  <div>
                    <v-icon class="mr-4">mdi-plus</v-icon>
                    Acréscimo
                  </div>
                  <div>
                    <b>{{ $format.decimal(sum_add_addition) }}</b>
                  </div>
                </div>

                <div class="text-right mt-8">
                  <span class="text--disabled">Valor A Pagar</span>
                  <h1 class="mt-3">
                    R$ {{ $format.decimal(sum_net_paid_value) }}
                  </h1>
                </div>
                <div class="mt-8">
                  <app-text-field
                    outlined
                    v-model="form.paid_at"
                    type="date"
                    label="Data do Pagamento"
                  />
                </div>
                <div class="mt-4">
                  <app-payment-method-select
                    label="Método de Pagamento"
                    v-model="form.payment_method"
                    outlined
                    :exclude="['bill']"
                    hide-details="auto"
                  />
                </div>
                <div class="mt-4">
                  <AccountSelect
                    :allowed-only="true"
                    v-model="form.account"
                    label="Selecionar caixa"
                  />
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions
        class="justify-end py-4"
        style="background-color: #f5f5f5"
      >
        <v-btn
          @click="dialog = false"
          text
          class="text-capitalize"
          color="secondary"
        >
          Cancelar
        </v-btn>
        <v-btn @click="handlePay()" class="text-capitalize" color="primary">
          Pagar
        </v-btn>
      </v-card-actions>
      <PaymentCalcForm
        ref="PaymentCalcForm"
        :dueValue="dueValue"
        @store="handleSetRatioValues($event)"
      />

      <BillReceivableInterestForm
        :sumValue="sum_value"
        ref="BillReceivableInterestForm"
        @store="handleSetInterest($event)"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import { format, differenceInDays } from "date-fns";
import AccountSelect from "@/components/account/ui/AccountSelect.vue";
import PaymentCalcForm from "@/components/bill-receivable/forms/PaymentCalcForm.vue";
import BillReceivableInterestForm from "@/components/bill-receivable/forms/BillReceivableInterestForm.vue";

export default {
  components: {
    AccountSelect,
    PaymentCalcForm,
    BillReceivableInterestForm,
  },

  data() {
    return {
      dialog: false,
      form: {
        account: null,
        paid_at: format(new Date(), "yyyy-MM-dd"),
        billReceivables: [],
        payment_method: "cash",
        interestRate: 0,
        gracePeriod: 0,
      },
    };
  },

  computed: {
    sum_add_payment() {
      return this.form.billReceivables.reduce(
        (sum, row) =>
          sum + (row.type == "in" ? row.add_payment : row.add_payment * -1),
        0
      );
    },

    sum_add_discount() {
      return this.form.billReceivables.reduce(
        (sum, row) =>
          sum + (row.type == "in" ? row.add_discount : row.add_discount * -1),
        0
      );
    },

    sum_add_addition() {
      return this.form.billReceivables.reduce(
        (sum, row) =>
          sum + (row.type == "in" ? row.add_addition : row.add_addition * -1),
        0
      );
    },

    sum_add_interest() {
      return this.form.billReceivables.reduce(
        (sum, row) =>
          sum + (row.type == "in" ? row.add_interest : row.add_interest * -1),
        0
      );
    },

    sum_net_paid_value() {
      return (
        this.sum_add_payment -
        this.sum_add_discount +
        this.sum_add_addition +
        this.sum_add_interest
      );
    },

    hasCredits() {
      return (
        this.form.billReceivables.filter((row) => {
          return row.type == "out" && row.bill.origin == "custom";
        }).length > 0
      );
    },

    hasReturns() {
      return (
        this.form.billReceivables.filter((row) => {
          return row.type == "out" && row.bill.origin == "sale";
        }).length > 0
      );
    },

    sum_value() {
      return (
        this.form.billReceivables.reduce(
          (sum, row) =>
            sum + this.$calc.round(row.bill.value - row.bill.paid_value),
          0
        ) +
        this.sum_credit_received_value +
        this.sum_add_interest
      );
    },

    dueValue() {
      return this.form.billReceivables.reduce(
        (sum, row) =>
          sum + this.$calc.round(row.bill.value - row.bill.paid_value),
        0
      );
    },
  },

  methods: {
    open(bills) {
      this.reset();

      this.dialog = true;

      this.setBillReceivables(bills);

      this.setInterest();
    },

    reset() {
      this.form = {
        account: null,
        billReceivables: [],
        credits: [],
        paid_at: format(new Date(), "yyyy-MM-dd"),
        interestRate: 3, // todo get from config
        gracePeriod: 5, // todo get from config
      };
    },

    setBillReceivables(bills) {
      let billsData = bills; //bills.filter((row) => row.type == "in");

      for (let index in billsData) {
        let billForm = this.getBillFormData(billsData[index]);

        this.$set(this.form.billReceivables, index, billForm);
      }
    },

    getBillFormData(bill) {
      let billForm = {};

      let absValue = Math.abs(bill.value);
      let absPaidValue = Math.abs(bill.paid_value);
      let remainderValue = absValue - absPaidValue;
      let dueValue = absValue - absPaidValue;

      billForm.id = bill.id;
      billForm.bill = bill;
      billForm.type = bill.type;
      billForm.due_value = dueValue;
      billForm.remainder_value = remainderValue;
      billForm.add_payment = remainderValue;
      billForm.add_discount = 0;
      billForm.add_addition = 0;
      billForm.add_interest = 0;

      return billForm;
    },

    calcNetPaidValue(billForm) {
      return (
        billForm.add_payment -
        billForm.add_discount +
        billForm.add_addition +
        billForm.add_interest
      );
    },

    getForm() {
      return {
        account: this.form.account,
        paid_at: this.form.paid_at,
        billReceivables: this.getBillReceivableFormData(
          this.form.billReceivables
        ),
        payment_method: this.form.payment_method,
      };
    },

    getBillReceivableFormData(billReceivables) {
      let billReceivableFormData = JSON.parse(JSON.stringify(billReceivables));

      for (let index in billReceivableFormData) {
        let billReceivableForm = billReceivableFormData[index];
        if (billReceivableForm.type == "out") {
          billReceivableForm.add_payment *= -1;
          billReceivableForm.add_discount *= -1;
          billReceivableForm.add_addition *= -1;
          billReceivableForm.add_interest *= -1;
        }
      }

      return billReceivableFormData;
    },

    async handlePay() {
      this.$loading.start();

      await this.$http
        .store("bill-receivable/pay-bill-receivable", this.getForm())
        .then((response) => {
          this.dialog = false;
          this.$emit("store");
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    handleCalcPayment() {
      this.$refs.PaymentCalcForm.open(this.form.billReceivables);
    },

    handleSetRatioValues(values) {
      for (let index in this.form.billReceivables) {
        let billReceivable = this.form.billReceivables[index];

        let ratio = billReceivable.due_value / this.dueValue;

        billReceivable.add_payment = this.$calc.round(values.payment * ratio);
        billReceivable.add_discount = this.$calc.round(values.discount * ratio);
        billReceivable.add_interest = this.$calc.round(values.interest * ratio);
      }
      // todo set remainder value
    },

    // handleSetDiscount(discount) {
    //   this.setDiscount(discount);
    // },

    // setDiscount(discountPercent) {
    //   for (let index in this.form.billReceivables) {
    //     let billForm = this.form.billReceivables[index];

    //     billForm.add_discount = this.$calc.round(
    //       (billForm.remainder_value * discountPercent) / 100
    //     );
    //   }
    // },

    handleAddInterest() {
      this.$refs.BillReceivableInterestForm.open(
        this.form.interestRate,
        this.form.gracePeriod
      );
    },

    handleSetInterest(interestData) {
      this.form.interestRate = interestData.interestRate;
      this.form.gracePeriod = interestData.gracePeriod;

      this.setInterest();
    },

    setInterest() {
      for (let index in this.form.billReceivables) {
        let billForm = this.form.billReceivables[index];

        if (billForm.type == "in") {
          billForm.add_interest = this.calcInterest(
            billForm.remainder_value,
            billForm.bill.due_date
          );
        }
      }
    },

    calcInterest(value, dueDate) {
      let days = this.calcDiffInDays(dueDate);

      if (days > this.form.gracePeriod) {
        return this.$calc.round(
          this.$calc.interest(value, this.form.interestRate, days)
        );
      }

      return 0;
    },

    calcDiffInDays(dueDate) {
      return differenceInDays(new Date(dueDate), new Date()) * -1;
    },
  },
};
</script>
