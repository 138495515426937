<template>
  <v-chip small :color="getColor()">{{
    getText()
  }}</v-chip>
</template>

<script>
export default {
  props: {
    value: {},
    statuses: {},
  },

  methods : {
    getColor() {
      try {
        return this.statuses[this.value].color
      } catch (e) {
        return 'primary'
      }
    },

    getText() {
      try {
        return this.statuses[this.value].text
      } catch (e) {
        return ''
      }
    }
  }
};
</script>

<style>
</style>