<template>
  <v-chip v-if="value" text small :color="key[value].color" v-text="key[value].text" />
</template>

<script>
export default {
  props: {
    value: {
    },
  },
  data() {
    return {
      key: {
        sale: {
          text: "Venda",
          color: "success",
        },
        order: {
          text: "Pedido",
          color: "primary",
        },
        waiting_approval: {
          text: "Para aprovação",
          color: "warning",

          // color: "warning",
        },
        proposal: {
          text: "Orçamento",
        },
        draft: {
          text: "Rascunho",
        },
        canceled: {
          text: "Cancelado",
          color: "error",
        },
      },
    };
  },
};
</script>

<style>
</style>