import Vue from 'vue'
//TODO: shortcut_name: nome do atalho a ser chamado no open
export default (sale) => {
    return getErrors(sale)
}

function getErrors(sale) {
    var errors = []

    if (validateCustomerWhenHasBills(sale)) {
        errors.push({ message: 'Adicione um cliente para vendas a prazo' })
    }
    if (checkMaximumDiscount(sale)) {
        errors.push({ message: 'Desconto máximo permitido excedido' })
    }

    if (checkOverdueBillReceivables(sale)) {
        errors.push({ message: 'Existe parcelas em atraso', shortcut_name: "billReceivableIndex" })
    }
    if (validateBlockedCustomer(sale)) {
        errors.push({ message: 'Cliente bloqueado', shortcut_name: "StoreCustomerForm" })
    }


    if (checkCustomerBuyLimit(sale)) {
        errors.push({ message: 'Limite de crédito excedido', shortcut_name: "CustomerCreditAnalysisDialog" })
    }
    if (checkHasCreditAnalysis(sale)) {
        errors.push({ message: 'Cliente sem análise de crédito', shortcut_name: "CustomerCreditAnalysisDialog" })
    }
    if (checkHasCreditAnalysisNotApproved(sale)) {
        errors.push({ message: 'Análise de crédito não aprovada', shortcut_name: "CustomerCreditAnalysisDialog" })
    }
    if (checkHasCreditAnalysisOverdue(sale)) {
        errors.push({ message: 'Análise de crédito vencida', shortcut_name: "CustomerCreditAnalysisDialog" })
    }
    if (checkHasCpf(sale)) {
        errors.push({ message: 'Cliente sem CPF', shortcut_name: "StoreCustomerForm" })
    }
    if (checkHasCnpj(sale)) {
        errors.push({ message: 'Cliente sem CNPJ', shortcut_name: "StoreCustomerForm" })
    }
    if (checkProductWithStockNegative(sale)) {
        errors.push({ message: 'Existem produtos com estoque insuficiente' })
    }
    if (checkProductPrice(sale)) {
        errors.push({ message: 'Existem produtos com valores desatualizados' })
    }

    return errors
}


function checkProductPrice(sale) {
    var products = []

    sale.sale_products.forEach(row => {
        
        if (row.product_variant.price_forward != row.price) {
            products.push(row)
        }
    })
    return products.length > 0

}


// Check Sale

function validateCustomerWhenHasBills(sale) {

    if (hasBills(sale)) {
        return (sale.customer == null)
    }
}

function checkMaximumDiscount(sale) {

    if (sale.sale_payment_terms) {

        var maximumDiscountPercent = sale.sale_payment_terms.reduce((max, row) => {
            if (row.payment_term) {
                return row.payment_term.max_discount_percent > max ? row.payment_term.max_discount_percent : max
            }
        }, 0)

        var maximumDiscountValue = sale.product_total * (maximumDiscountPercent / 100);

        return Math.abs(sale.discount) > Math.abs(maximumDiscountValue) ? true : false

    }
}


// Check Customer Stats

function checkOverdueBillReceivables(sale) {

    if (hasCustomer(sale) && hasBills(sale) && hasCustomerStats(sale)) {

        var customer = getCustomer(sale);

        return customer.stats.net_overdue_value.sum_due_value > 0
    }
}

function checkHasCpf(sale) {

    if (hasCustomer(sale) && hasBills(sale)) {

        var customer = getCustomer(sale);
        return customer.person_type == "fisica" && customer.cpf == null
    }

}
function checkHasCnpj(sale) {

    if (hasCustomer(sale) && hasBills(sale)) {

        var customer = getCustomer(sale);
        return customer.person_type == "juridica" && customer.cnpj == null
    }

}



function validateBlockedCustomer(sale) {

    if (hasCustomer(sale)) {

        return sale.customer.is_blocked == 1

    }
}


// Check Credit Analysis
function checkCustomerBuyLimit(sale) {

    var customer = getCustomer(sale);
    if (hasCustomer(sale) && hasBills(sale) && hasCustomerStats(sale) && hasLastCreditAnalysis(customer)) {


        var billTotal = customer.stats.to_pay_bill_receivables_totals.sum_due_value
        var saleTotal = parseFloat(sale.net_total)
        var creditLimit = hasLastCreditAnalysis(customer) ? customer.last_credit_analysis.credit_limit : 0


        var total = billTotal + saleTotal
        return (parseFloat(total) > parseFloat(creditLimit))



    }

}

function checkHasCreditAnalysis(sale) {

    if (hasCustomer(sale) && hasBills(sale)) {

        var customer = getCustomer(sale);

        return customer.last_credit_analysis == null ? true : false
    }
}

function checkHasCreditAnalysisOverdue(sale) {

    if (hasCustomer(sale) && hasBills(sale)) {

        var customer = getCustomer(sale);

        if (hasLastCreditAnalysis(customer)) {
            return customer.last_credit_analysis.is_overdue
        }
    }
}

function checkHasCreditAnalysisNotApproved(sale) {

    if (hasCustomer(sale) && hasBills(sale)) {

        var customer = getCustomer(sale);

        if (hasLastCreditAnalysis(customer)) {
            return customer.last_credit_analysis.credit_status != 'approved'
        }
    }
}






// Helpers

function hasBills(sale) {
    return sale.sale_bills.filter(row => row.payment_method == 'bill').length > 0
}

function hasCustomer(sale) {
    return sale.customer != null && sale.customer.id != null
}

function hasLastCreditAnalysis(customer) {
    return customer.last_credit_analysis != null
}

function hasCustomerStats(sale) {
    return sale.customer_stats != null
}

function getCustomer(sale) {

    return {
        ...sale.customer,
        stats: sale.customer_stats,
    }
}

function checkProductWithStockNegative(sale) {
    return sale.sale_products.filter(row => {
        if (!row.product.allow_sell_stock_negative) {

            return parseFloat(row.quantity) > parseFloat(row.product_company.available_stock)
        }
    }).length > 0
}