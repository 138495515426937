
import format from './../utils/format'
import Vue from 'vue'


  Vue.use({
    install(Vue) {
      Vue.prototype.$format = format
      Vue.$format = format
    }
  })

  export default (_) => { }