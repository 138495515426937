<template>
  <v-select
    v-bind="$attrs"
    hide-details="auto"
    v-on="$listeners"
    :dense="dense"
  />
</template>

<script>
export default {
  props : {
    dense : {
      default : true
    }
  }
};
</script>
