<template>
  <app-select
    :label="label"
    v-bind="$attrs"
    :dense="dense"
    v-on="$listeners"
    :items="companies"
    :item-text="itemText"
    :item-value="itemValue"
  />
</template>

<script>
export default {
  props: {
    itemText: {
      default() {
        return (val) => val.name;
      },
    },

    itemValue: {
      default() {
        return (val) => val;
      },
    },

    dense: {
      default: true,
    },
  },

  computed: {
    companies() {
      let companies = this.$store.state.company.companies;

      if (this.$attrs.exclude) {
        companies = companies.filter(
          (row) => row.id !== this.$attrs.exclude.id
        );
      }

      if (this.$attrs.filter) {
        companies = companies.filter((row) => this.$attrs.filter(row));
      }

      return companies;
    },
    label() {
      return this.$attrs.label ? this.$attrs.label : "Empresa";
    },
  },
};
</script>

<style>
</style>