<template>
  <v-dialog v-model="dialog" width="400px">
    <v-card>
      <v-card-title>Calcular pagamento</v-card-title>
      <v-card-text>
        <v-row>
          <v-col class="text-right">
            <small>Saldo Devedor</small>
            <br>
            <h2>{{ $format.decimal(dueValue) }}</h2>
          </v-col>
        </v-row>
        <v-row class="mt-4">
          <v-col>
            <app-number-field
              v-model="form.payment"
              label="Pagamento"
              @change="setDiscount()"
            />
          </v-col>
        </v-row>
        <v-row class="mt-4">
           <v-col>
            <app-number-field
              v-model="form.discount_percent"
              label="Desconto (%)"
              @change="setDiscount()"
            />
          </v-col>
          <v-col>
            <app-number-field
              v-model="form.discount"
              label="Desconto (R$)"
              @change="setDiscountPercent()"
            />
          </v-col>
        </v-row>
        <v-row class="mt-4">
          <v-col>
            <app-number-field
              v-model="form.interest"
              label="Juros (R$)"
            />
          </v-col>
        </v-row>
         <v-row>
          <v-col class="text-right">
            <small>Valor a pagar</small>
            <br>
            <h2>{{ $format.decimal(netPayment) }}</h2>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn class="text-capitalize" text @click="dialog = false">
          Voltar
        </v-btn>
        <v-btn class="text-capitalize" color="primary" @click="handleStore()">
          Aplicar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dueValue: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      dialog: false,
      form: {
        payment : 0,
        discount : 0,
        interest : 0,
      },
    };
  },

  computed : {
    netPayment() {
      return this.form.payment - this.form.discount + this.form.interest;
    },
  },  

  created() {
    this.reset();
  },

  methods: {
    open() {
      this.dialog = true;
      this.reset();
    },

    reset() {
      this.form = {
        payment : 0,
        discount : 0,
        interest : 0,
      };
    },


    setDiscount() {
      this.form.discount = this.form.payment * (this.form.discount_percent / 100);
    },

    setDiscountPercent() {
      if (this.form.payment != 0 ) {
        this.form.discount_percent = (this.form.discount / this.form.payment) * 100;
      }
    },

    handleStore() {
      this.dialog = false;

      this.$emit("store", this.form);
    },
  },
};
</script>

<style></style>
