
const paymentMethods =

  [
    {
      value: 'cash',
      icon: 'mdi-cash',
      label: 'Dinheiro',
      color: 'success',
      hasInstallments: false,
      hasDueDate: false,
    },
    {
      value: 'credit_card',
      icon: 'mdi-credit-card-outline',
      label: 'Cartão de Credito',
      color: 'disable',
      hasInstallments: true,
      hasDueDate: false,
    },
    {
      value: 'debit_card',
      icon: 'mdi-credit-card',
      label: 'Cartão de Débito',
      color: 'disable',
      hasInstallments: false,
      hasDueDate: false,
    },
    {
      value: 'check',
      icon: 'mdi-checkbook',
      label: 'Cheque',
      color: 'disable',
      hasInstallments: false,
      hasDueDate: false,
    },
    {
      value: 'bill',
      icon: 'mdi-file-edit',
      label: 'Crediário',
      color: 'disable',
      hasInstallments: false,
      hasDueDate: true,
    },
    // {
    //   value: 'pix',
    //   icon: 'mdi-qrcode',
    //   label: 'Pix',
    //   color: 'disable',
    //   hasInstallments: false,
    //   hasDueDate: false,
    // }
  ]


export default paymentMethods