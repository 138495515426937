import Vue from "vue";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";

export default async function bugsnagMiddleware(to, from, next) {
  // if (process.env.NODE_ENV === "production" && process.env.VUE_APP_BUGSNAG_API_KEY) {
  //   Bugsnag.start({
  //     apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
  //     plugins: [new BugsnagPluginVue()],
  //   });

  //   const bugsnagVue = Bugsnag.getPlugin("vue");
  //   bugsnagVue.installVueErrorHandler(Vue);
  // }
}
