<template>
  <v-autocomplete
    label="Plano de conta"
    v-bind="$attrs"
    :dense="dense"
    v-on="$listeners"
    :items="accountPlans"
    :item-text="itemText"
    :item-value="itemValue"
  />
</template>

<script>
export default {
  props: {
    itemText: {
      default() {
        return (val) => `${val.code} - ${val.name}`;
      },
    },

    itemValue: {
      default() {
        return (val) => val;
      },
    },

    dense: {
      default: true,
    },
  },

  data : () => ({
    accountPlans : [],
  }),

  created() {
    this.select()
  },

  methods: {
    select() {
      this.$http.index('account/account-plan').then((response) => {
        this.accountPlans = response.accountPlans;
      });
    },
  },
};
</script>

<style>
</style>