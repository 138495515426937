import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import auth from './plugins/auth'
import acl from './plugins/acl'
import apexCharts from './plugins/apexCharts'
import calc from './plugins/calc'
import format from './plugins/format'
import globalComponents from './plugins/globalComponents'
import http from './plugins/http'
import vueCurrencyInput from './plugins/vueCurrencyInput'
import vueDebounce from './plugins/vueDebounce'
import vuetify from './plugins/vuetify'
import loading from './plugins/loading'
import "./assets/style/variables.scss";
import 'material-symbols';
import snackbar from '@/plugins/snackbar'
import print from '@/plugins/print'
import db from '@/plugins/db'
import app from '@/plugins/app'
import printTag from '@/plugins/printTag'
import shortcut from '@/plugins/shortcut'


Vue.config.productionTip = false

new Vue({
  shortcut,
  print,
  db,
  auth,
  acl,
  apexCharts,
  router,
  store,
  calc,
  app,
  printTag,
  format,
  globalComponents,
  http,
  vueCurrencyInput,
  vueDebounce,
  vuetify,
  snackbar,
  loading,
  render: h => h(App)
}).$mount('#app')
