<template>
  <div v-if="hasPages" class="text-center pt-2">
    <v-pagination
      :total-visible="9"
      @input="$emit('click', $event)"
      :value="data.current_page"
      :length="data.last_page"
    />
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: function () {
        return {
          total: 0,
        };
      },
    },
  },

  computed: {
    getResult() {
      if (this.data.total > 0) {
        return true;
      }

      return false;
    },

    plural() {
      if (this.data.total > 1) {
        return "s";
      }

      return "";
    },

    hasPages() {
      if (this.data.last_page > 1) {
        return true;
      }

      return false;
    },
  },
};
</script>

<style>
</style>