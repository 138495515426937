<template>
  <div>
    <template v- v-for="(phone, index) in phones">
      <div class="mr-3" :key="index" v-if="phone.contact">
        <b v-text="markers[phone.marker]" />
        {{ phone.contact }}
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    phones: {},
  },
  data() {
    return {
      markers: {
        comercial: "Comercial:",
        home: "Casa:",
        phone: "Celular:",
        whatsapp: "Whatsapp:",
        null: "Outro:",
      },
    };
  },
};
</script>

<style>
</style>