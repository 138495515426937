<template>
  <div>
    <v-app-bar class="not-print" app flat>
      <v-toolbar-title><h3 v-text="$route.meta.displayName" /></v-toolbar-title>

      <v-spacer />

      <v-chip-group
        :value="type_print"
        active-class="primary"
        column
        mandatory
        @change="handleChangeTypePrint($event)"
      >
        <v-chip value="thermal_printing">
          <app-icon left>receipt_long </app-icon> Impressão Térmica
        </v-chip>
        <v-chip value="common_printing">
          <app-icon left> article</app-icon>Impressão Comum
        </v-chip>
      </v-chip-group>

      <v-btn @click="print()" class="text-capitalize" color="primary">
        <app-icon left>print</app-icon>
        Imprimir
      </v-btn>
    </v-app-bar>
    <v-main class="not-print" />
    <slot />
  </div>
</template>

<script>
import AppNavigationDrawer from "@/components/app/sections/AppNavigationDrawer";
export default {
  components: { AppNavigationDrawer },

  computed: {
    type_print() {
      return this.$store.getters["app/getTypePrint"];
    },
  },

  methods: {
    handleChangeTypePrint(type_print) {
      this.$store.commit("app/setTypePrint", type_print);
    },
    print() {
      window.print();
    },
  },
  async created() {
    this.$store.dispatch("company/index");
  },
};
</script>
<style scoped>
</style>