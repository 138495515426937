import store from '@/store'
import Vue from 'vue';

Vue.use({
  install(Vue) {
    Vue.prototype.$snackbar = function (props) {

      store.commit('app/snackbar', props)

    }
  }
})

export default (_) => { }