<template>
  <v-text-field
    v-model="formatedNumber"
    ref="input"
    type="text"
    v-currency="options"
    v-bind="$attrs"
    @input="handleInput()"
    @change="handleChange()"
    hide-details="auto"
    dense
    reverse
  />
</template>

<script>
import { setValue, parse } from "vue-currency-input";

export default {
  props: {
    defaultValue: {
      default: 0,
    },
    value: {},
    precision: {
      default: 2,
    },
    allowNegative: {
      default: false,
    },
    forceZero: {
      default: true
    }
  },

  data: () => ({
    formatedNumber: 0,
  }),

  computed: {
    options() {
      return {
        locale: "de-DE",
        distractionFree: false,
        "auto-decimal-mode": false,
        currency: null,
        precision: this.precision,
        allowNegative: this.allowNegative,
      };
    },
  },

  watch: {
    value(value) {
      setValue(this.$refs.input, value);
    },
  },

  mounted() {
    setValue(this.$refs.input, this.value);
  },

  methods: {
    handleInput() {
      this.$emit("input", this.getNumber());
    },

    getNumber() {
      return parse(this.formatedNumber, this.options);
    },

    handleChange() {
      this.checkNull()

      this.$emit("change");
    },

    checkNull() {
      if (this.forceZero && !this.formatedNumber) {
        setValue(this.$refs.input, 0);
      }
    }
  },
};
</script>
