import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import authMiddleware from "./middlewares/authMiddleware"
import aclMiddleware from "./middlewares/aclMiddleware"
import bugsnagMiddleware from "./middlewares/bugsnagMiddleware"
import products from "./modules/products"
import customers from "./modules/customers"
import sales from "./modules/sales"
import finances from "./modules/finances"
import admin from "./modules/admin"
import outers from "./modules/outers"
import deliveries from "./modules/deliveries"
import dashboards from "./modules/dashboards"
Vue.use(VueRouter)

const routes = [
  ...products,
  ...customers,
  ...sales,
  ...finances,
  ...admin,
  ...outers,
  ...deliveries,
  ...dashboards,

  {
    path: '/login',
    name: 'login',
    meta: { layout: 'AuthLayout', name: 'Login', auth: 'guest', },
    component: () => import('../views/LoginIndex')
  },
  {
    path: '/',
    beforeEnter: (to, from, next) => {
      if (Vue.$acl.isSalesman() || Vue.$acl.isStockman() || Vue.$acl.isBuyer() || Vue.$acl.isManager()) {
        next('/dashboard/produtos')
      } else {
        next('/home')
      }
    }
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: { layout: 'appLayout', displayName: 'Início' },
  },
  {
    path: '*',
    redirect: '/',
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {

  await authMiddleware(to, from, next)

  await aclMiddleware(to, from, next)

  await bugsnagMiddleware(to, from, next)

});

export default router
