<template>
  <div>
    <template v-for="(row, index) in parcels">
      <v-row class="align-center" :key="index">
        <v-col md="4">
          <app-text-field
            v-model="row.document"
            :disabled="disableDocument"
            label="Documento"
          />
        </v-col>
        <v-col md="3">
          <app-number-field
            v-model="row.value"
            :allowNegative='allowNegative'
            label="Valor"
          />
        </v-col>
        <v-col md="4">
          <app-date-field
            v-model="row.due_date"
            :disabled="disableDueDate"
            label="Vencimento"
            type="date"
          />
        </v-col>
        <v-col class="text-center" md="1">
          <v-btn
            v-if="hasMoreThanOneRow"
            x-small
            text
            fab
            @click="removeParcel(index)"
          >
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <v-row>
      <v-col class="text-left py-0" cols="12">
        <v-btn
          class="text-capitalize"
          small
          text
          :disabled="disabledAdd"
          color="info"
          @click="addParcel()"
        >
          Adicionar parcela
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  props: {
    value: {},
    setForm: {},

    disabledAdd: {
      default: false,
    },

    disableDocument: {
      default: false,
    },
    disableDueDate: {
      default: false,
    },
    allowNegative: {
      default: false,
    },
    docPrefix: {
      default: "",
    },
  },

  data: () => ({
    parcels: [],
  }),

  watch: {
    value(val) {
      this.parcels = val;
    },

    parcels: {
      handler(val) {
        this.$emit("input", val);
      },
      deep: true,
    },
  },

  computed: {
    hasMoreThanOneRow() {
      return this.parcels.length > 1;
    },

    setValues() {
      this.setForm.value = this.parcels.value;
      this.setForm.document = this.parcels.document;
      this.setForm.due_date = this.parcels.due_date;
      return this.setValues;
    },
  },

  created() {
    this.parcels = this.value;
  },

  methods: {
    addParcel() {
      this.parcels.push({
        document: `${this.docPrefix} ${this.parcels.length + 1}`,
        value: 0,
        due_date: null,
      });
    },

    removeParcel(index) {
      this.$delete(this.parcels, index);
    },

    calcDate(index) {
      // this.parcels[index].due_date = moment({
      //   h: 0,
      //   m: 0,
      //   s: 0,
      //   ms: 0,
      // })
      //   .add(this.parcels[index].days, "d")
      //   .format("YYYY-MM-DD");
    },

    calcDays(index) {
      // var due_date = moment(this.parcels[index].due_date, "YYYY-MM-DD");
      // this.parcels[index].days = due_date.diff(
      //   moment({
      //     h: 0,
      //     m: 0,
      //     s: 0,
      //     ms: 0,
      //   }),
      //   "d"
      // );
    },
  },
};
</script>

<style>
</style>
