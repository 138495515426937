<template>
  <v-dialog v-model="dialog" persistent max-width="700px" origin="left top">
    <v-card>
      <v-card-title> Editar Conta a Receber </v-card-title>

      <v-card-text>
        <h3 class="mt-2 mb-2">Detalhes</h3>
        <v-row>
          <v-col>
            <company-select v-model="form.company.id" :item-value="(val) => val.id"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            ><app-text-field label="Descrição" v-model="form.description" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <CustomerSearch v-model="form.customer" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <AccountPlanSearch v-model="form.account_plan" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              v-model="form.details"
              label="Detalhes"
              hide-details="auto"
              dense
              outlined
            />
          </v-col>
        </v-row>

        <v-row class="align-center">
          <v-col>
            <app-text-field v-model="form.document" label="Documento" />
          </v-col>
          <v-col>
            <app-number-field v-model="form.value" label="Valor" />
          </v-col>
          <v-col>
            <app-date-field
              v-model="form.due_date"
              label="Vencimento"
              type="date"
            />
          </v-col>
        </v-row>

        <h3 class="mt-8 mb-2">Situação</h3>
        <v-row>
          <v-col>
            <v-chip-group v-model="form.status" mandatory>
              <v-chip value="to_pay" color="primary" small filter>
                A Receber
              </v-chip>
              <v-chip
                v-if="$acl.can('billPayableCancel')"
                value="canceled"
                color="error"
                small
                filter
              >
                Cancelada
              </v-chip>
            </v-chip-group>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="dialog = false"
          text
          class="text-capitalize"
          color="secondary"
          >Cancelar</v-btn
        >
        <v-btn @click="handleSave()" class="text-capitalize" color="primary"
          >Salvar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CustomerSearch from "@/components/customer/ui/CustomerSearch";
import CompanySelect from "@/components/company/ui/CompanySelect.vue";
import AccountPlanSearch from "@/components/account-plan/ui/AccountPlanSearch";
import BillPayableFormParcels from "@/components/bill-payable/forms/BillPayableFormParcels";

export default {
  components: {
    CustomerSearch,
    CompanySelect,
    AccountPlanSearch,
    BillPayableFormParcels,
  },

  data() {
    return {
      dialog: false,
      form: null,
      baseForm: {
        company: {},
        customer: null,
        description: null,
        account_plan: null,
        details: null,
        document: null,
        value: 0,
        due_date: null,
        status: "to_pay",
      },
    };
  },

  created() {
    this.reset();
  },

  methods: {
    async open(id) {
      this.reset();
      await this.loadBillPayable(id);
      this.dialog = true;
    },

    async loadBillPayable(id) {
      let response = await this.$http.show(
        "bill-receivable/bill-receivable",
        id
      );

      this.form = response.bill_receivable;
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },

    handleSave() {
      this.store().then(() => {
        this.$emit("store");
        this.dialog = false;
      });
    },

    async store() {
      return new Promise(async (resolve, reject) => {
        this.$store.commit("app/showLoading");
        await this.$http
          .update("bill-receivable/bill-receivable", this.form.id, this.form)
          .then((response) => {
            resolve();
          })
          .catch((error) => {});
        this.$store.commit("app/hideLoading");
      });
    },
  },
};
</script>
