<template>
  <app-status-chip :statuses="statuses" :value="value" />
</template>

<script>
export default {
  props: {
    value: {},
  },

  data: () => ({
    statuses: {
      renegotiated: {
        text: "Renegociado",
        color: "disabled",
      },
      to_pay: {
        text: "A Receber",
        color: "secondary",
      },
      paid: {
        text: "Pago",
        color: "primary",
      },
      canceled: {
        text: "Cancelado",
        color: "danger",
      },
    },
  }),
};
</script>

<style>
</style>