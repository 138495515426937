<template>
  <v-switch
    v-bind="$attrs"
    hide-details="auto"
    v-model="switchValue"
    @change="handleChange($event)"
    :true-value="1"
    :false-value="0"
    :label="label"
  >
  </v-switch>
</template>

<script>
export default {
  props: {
    value: {},
  },

  data: () => ({
    switchValue: 1,
  }),

  computed : {
    label() {
      return (this.switchValue == 1) ? 'Cadastro Ativo' : 'Cadastro Inativo' 
    }
  },
  watch : {
    value(val) {
      this.switchValue = val
    }
  },

  
  created() {
    this.switchValue = this.value;
  },


  methods: {
    handleChange(input) {
      this.$emit("input", input);
    },
  },
};
</script>
