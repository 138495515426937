// import printUrls from "../enums/printUrls"

class PrintTag {
  constructor(url) {
    this.url = url;
  }

  get props() {
    return {
      page_settings: {
        itensPerPage: 24,
        height: 29.7,
        width: 21,
        padding: 0.5,
        margin: 0.5,
        alignment: "center",
      },

      tag_settings: {
        height: 3.5,
        width: 6.5,
      },




    };




  }



  SetPageSettings(props) {

    this.props.page_settings = props;
  }



  size(value) {
    return value * 37.795275590551;
  }

  fontSize(value) {
    return value * 2.834645669291;
  }





}

export default PrintTag;