<template>
  <v-chip :color="payment_method.color" small v-text="payment_method.label" />
</template>

<script>
import paymentMethods from "@/enums/paymentMethods";

export default {
  props: {
    value: {},
  },

  computed: {
    payment_method() {
      return paymentMethods.filter((row) => {
        return row.value === this.value;
      })[0];
    },
  },
};
</script>

<style>
</style>