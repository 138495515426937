<template>
  <i>
    <v-btn v-if="canAccess" @click.stop="open()" color="info" small icon>
      <app-icon small>north_east </app-icon>
    </v-btn>
    <component
      v-if="!isLink"
      :is="component"
      ref="ShortcutComponent"
      v-bind="$props"
      v-on="$listeners"
    />
  </i>
</template>

<script>
//TODO: importar os componentes que serão utilizados como atalho
import StoreCustomerForm from "@/components/customer/forms/StoreCustomerForm.vue";
import CustomerCreditAnalysisDialog from "@/components/customer/sections/CustomerCreditAnalysisDialog.vue";
import PayBillReceivable from "@/components/account/sections/PayBillReceivableDialog.vue";
import SaleView from "@/components/sales/sections/SaleView.vue";
export default {
  components: {
    StoreCustomerForm,
    CustomerCreditAnalysisDialog,
    PayBillReceivable,
    SaleView,
  },
  props: {
    name: {
      type: String,
      required: true,
    },

    type: {},

    params: {},
    query: {},
  },

  data() {
    return {
      //TODO: relação  componente x permissão
      permission: {
        StoreCustomerForm: "customerCreateEdit",
        CustomerCreditAnalysisDialog: "customerCreditAnalysis",
        PayBillReceivable: "billReceivableIndex",
        SaleView: "salesIndex",
        billReceivableIndex: "billReceivableIndex",
        stockAuditProducts: "stockAuditIndex",
        ViewStockTransfer: "stockTransferIndex",
        PurchaseForm: "purchasesCreateEdit",
      },
      component: null,
    };
  },

  created() {
    this.component = this.name;
  },

  computed: {
    //TODO: verifica se o componente é um link
    isLink() {
      return (
        this.$shortcut.get(this.name).type == "link" || this.type == "link"
      );
    },

    //TODO: verifica se o usuário tem permissão para acessar o componente
    canAccess() {
      return this.$acl.can(this.permission[this.name]);
    },

    //TODO: verifica se o componente possui função
    functionComponent() {
      return this.params.functionName || this.params.function;
    },
  },

  methods: {
    //TODO: executa o atalho
    open() {
      this.$nextTick(() => {
        //TODO: verifica se o atalho é um link
        if (this.isLink) {
          this.handleLink();
        }
        //TODO: verifica se o atalho é um componente
        if (!this.isLink) {
          this.handleComponent();
        }
      });
    },

    //TODO: abre o componente caso o usuário tenha permissão
    handleComponent() {
      if (this.canAccess) {
        this.openComponent();
      }
    },
    //TODO: abre o componente e executa a função
    openComponent() {
      this.component = this.name;
      if (this.component != null) {
        if (this.functionComponent) {
          this.$refs.component[this.functionComponent](this.params);
        }
        if (!this.functionComponent) {
          this.$refs.ShortcutComponent.open(this.params);
        }
      }
    },
    //TODO: abre o link
    handleLink() {
      var params = { query: this.query, params: this.params };
      this.$shortcut.open(this.name, params);
    },
  },
};
</script>

<style>
</style>