<template>
  <div>
    <!-- <v-system-bar app>
      <v-spacer></v-spacer>

      <v-icon>mdi-square</v-icon>

      <v-icon>mdi-circle</v-icon>

      <v-icon>mdi-triangle</v-icon>
    </v-system-bar> -->
    <AppAlertOffline />

    <v-app-bar app flat>
      <v-app-bar-nav-icon @click.stop="handleChangeDrawer()" />

      <v-toolbar-title
        ><h3 class="d-inline">{{ $route.meta.displayName }}</h3> <v-chip class="ml-2" v-if="showDevelopmentWarningChip" color="error">Ambiente de teste</v-chip></v-toolbar-title
      >
      <br>
      
    </v-app-bar>
    <!-- #171D34 -->

    <AppNavigationDrawer />

    <v-main color="backgroud">
      <v-container class="pa-md-8" fluid>
        <!-- <router-view /> -->
        <slot></slot>
      </v-container>
    </v-main>
  </div>
</template>

<script>

import AppNavigationDrawer from "@/components/app/sections/AppNavigationDrawer"
import AppAlertOffline from "@/components/app/sections/AppAlertOffline";
export default {
  components : { AppNavigationDrawer, AppAlertOffline },

  computed : {
    showDevelopmentWarningChip() {
      return this.$store.state.auth.userData.metadata.appEnv == 'development'
    }
  },

  methods: {
    handleChangeDrawer() {
      this.$store.commit("app/toggleDrawer");
    },
  },
  async created() {
    this.$store.dispatch("company/index");
  },


};
</script>
<style lang="scss"  >
</style>