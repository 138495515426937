export default [

  {
    'origin': 'sale',
    'url': '/vendas/venda/imprimir/'
  },

  {
    'origin': 'bill_receivable_credit',
    'url': `/contas/vale-credito-imprimir/`
  },

  {
    'origin': 'bill_receivable',
    'url': '/contas/recibo-imprimir/'
  },
  {
    'origin': 'bill_receivable_group',
    'url': '/contas/recibo-imprimir/'
  },
  {
    'origin': 'delivery',
    'url': '/entregas/entrega/imprimir/',
  },
  {
    'origin': 'stock_transfer',
    'url': '/transferencia/imprimir/'

  },
]

