export default [
  {
    path: '/clientes',
    name: 'customerIndex',
    meta: { layout: 'appLayout', displayName: 'Clientes', permission: 'customersIndex' },
    component: () => import('@/views/customers/CustomersIndex.vue')
  },

  {
    path: '/clientes/consulta-crediario',
    name: 'consultInstallmentIndex',
    meta: { layout: 'appLayout', displayName: 'Consulta Crediário', permission: 'consultInstallmentIndex' },
    component: () => import('@/views/customers/ConsultInstallmentIndex.vue')
  },

  {
    path: '/clientes/:id/consulta-crediario',
    name: 'consultInstallmentIndexId',
    meta: { layout: 'appLayout', displayName: 'Consulta Crediário', permission: 'consultInstallmentIndex' },
    component: () => import('@/views/customers/ConsultInstallmentIndex.vue')


  }
  ,
]