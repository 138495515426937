
import Shortcut from './../utils/shortcut';
import Vue from 'vue';

Vue.use({
  install(Vue) {
    Vue.prototype.$shortcut = new Shortcut()
    Vue.$shortcut = new Shortcut();
  }
})

export default (_) => { }