import Vue from 'vue'

class SaleObject {

  constructor() {
    this.sale = {}
  }

  setSale(sale) {

    this.sale = this.setProductsValuesPositive(sale)

    this.sale = this.setFinanceValuesPositive(sale)

    this.saleParams = {}
  }

  getSaleForm() {

    this.setGeneralDiscount()

    this.calcTotals()

    this.calcPercents()

    return this.sale
  }

  getSaleParams() {

    let sale = JSON.parse(JSON.stringify(this.getSaleForm()))

    if (sale.operation.movement_stock_type == 'in') {
      sale = this.setProductsValuesNegative(sale)
    }

    if (sale.operation.movement_finance_type == 'out') {
      sale = this.setFinanceValuesNegative(sale)
    }

    return this.form(sale)
  }

  setGeneralDiscount() {
    if (this.sale.sale_products.length > 0) {

      var productSubTotals = this.sale.sale_products.map(row => Number(row.product_total) - Number(row.product_discount))

      var generalDiscountArr = calcGeneralDiscountArr(productSubTotals, this.sale.general_discount)

      for (let i in this.sale.sale_products) {

        this.sale.sale_products[i].general_discount = generalDiscountArr[i]

        this.sale.sale_products[i].sub_total = Number(this.sale.sale_products[i].product_total) - Number(this.sale.sale_products[i].product_discount)

        this.sale.sale_products[i].discount = Number(this.sale.sale_products[i].product_discount) + Number(this.sale.sale_products[i].general_discount)

        this.sale.sale_products[i].net_total = Number(this.sale.sale_products[i].product_total) - Number(this.sale.sale_products[i].discount)

        this.sale.sale_products[i].net_price = Number(this.sale.sale_products[i].net_total) / Number(this.sale.sale_products[i].quantity)
      }
    }
  }

  setProductsValuesNegative(sale) {

    if (sale.sale_products.length > 0) {
      for (let index in sale.sale_products) {

        sale.sale_products[index].quantity = Math.abs(sale.sale_products[index].quantity) * -1
        sale.sale_products[index].product_total = sale.sale_products[index].product_total * -1
        sale.sale_products[index].product_discount = sale.sale_products[index].product_discount * -1
        sale.sale_products[index].sub_total = sale.sale_products[index].sub_total * -1
        sale.sale_products[index].general_discount = sale.sale_products[index].general_discount * -1
        sale.sale_products[index].discount = sale.sale_products[index].discount * -1
        sale.sale_products[index].net_total = sale.sale_products[index].net_total * -1
      }
    }

    sale.product_total = sale.product_total * -1

    return sale
  }

  setProductsValuesPositive(sale) {

    if (sale.sale_products.length > 0) {
      for (let index in sale.sale_products) {

        sale.sale_products[index].quantity = Math.abs(sale.sale_products[index].quantity)
        sale.sale_products[index].product_total = Math.abs(sale.sale_products[index].product_total)
        sale.sale_products[index].product_discount = Math.abs(sale.sale_products[index].product_discount)
        sale.sale_products[index].sub_total = Math.abs(sale.sale_products[index].sub_total)
        sale.sale_products[index].general_discount = Math.abs(sale.sale_products[index].general_discount)
        sale.sale_products[index].discount = Math.abs(sale.sale_products[index].discount)
        sale.sale_products[index].net_total = Math.abs(sale.sale_products[index].net_total)
      }
    }

    sale.product_total = Math.abs(sale.product_total)

    return sale
  }

  setFinanceValuesNegative(sale) {
    if (sale.sale_bills.length > 0) {
      for (let index in sale.sale_bills) {
        sale.sale_bills[index].value = Math.abs(sale.sale_bills[index].value) * -1
      }
    }

    if (sale.sale_payment_terms.length > 0) {
      for (let index in sale.sale_payment_terms) {
        sale.sale_payment_terms[index].value = Math.abs(sale.sale_payment_terms[index].value) * -1
      }
    }

    return sale
  }

  setFinanceValuesPositive(sale) {
    if (sale.sale_bills.length > 0) {
      for (let index in sale.sale_bills) {
        sale.sale_bills[index].value = Math.abs(sale.sale_bills[index].value)
      }
    }

    if (sale.sale_payment_terms.length > 0) {
      for (let index in sale.sale_payment_terms) {
        sale.sale_payment_terms[index].value = Math.abs(sale.sale_payment_terms[index].value)
      }
    }

    return sale
  }

  calcTotals() {
    this.sale.product_total = 0
    this.sale.product_discount = 0
    this.sale.sub_total = 0
    this.sale.discount = 0
    this.sale.shipping = 0
    this.sale.expenses = 0
    this.sale.net_total = 0

    for (let sale_product of this.sale.sale_products) {
      this.sale.product_total += Number(sale_product.product_total)
      this.sale.product_discount += Number(sale_product.product_discount)
      this.sale.sub_total += Number(sale_product.sub_total)
      this.sale.discount += Number(sale_product.discount)
      this.sale.shipping += Number(sale_product.shipping)
      this.sale.expenses += Number(sale_product.expenses)
      this.sale.net_total += Number(sale_product.net_total)
    }
  }

  calcPercents() {

    this.sale.discount_percent = 0

    if (this.sale.product_total != 0) {
      this.sale.discount_percent = this.sale.discount * 100 / this.sale.product_total
    }
  }


  form(sale) {


    return {
      delivery_type: sale.delivery_type,
      negotiation_value: sale.negotiation_value,
      has_negotiation: sale.has_negotiation,
      negotiation_comments: sale.negotiation_comments,
      discount: sale.discount,
      discount_percent: sale.discount_percent,
      expenses: sale.expenses,
      general_discount: sale.general_discount,
      general_discount_percent: sale.general_discount_percent,
      id: sale.id,
      net_total: sale.net_total,
      notes: sale.notes,
      product_discount: sale.product_discount,
      product_total: sale.product_total,
      shipping: sale.shipping,
      status: sale.status,
      sub_total: sale.sub_total,

      company: {
        id: sale.company.id,
      },

      salesman: {
        id: sale.salesman.id,
      },

      operation: {
        id: sale.operation.id,
      },

      sale_bills: sale.sale_bills,

      customer: this.getCustomer(sale),

      sale_payment_terms: this.getSalePaymentTerms(sale),

      sale_products: this.getSaleProducts(sale),

    }
  }


  getCustomer(sale) {
    return sale.customer ? { id: sale.customer.id } : null
  }
  getSalePaymentTerms(sale) {

    return sale.sale_payment_terms.map((sale_payment_term) => {
      return {
        installments: sale_payment_term.installments,
        value: sale_payment_term.value,
        payment_term: {
          id: sale_payment_term.payment_term.id
        }
      }
    })
  }
  getSaleProducts(sale) {
    return sale.sale_products.map((sale_product) => {
       return {
        discount: sale_product.discount,
        expenses: sale_product.expenses,
        general_discount: sale_product.general_discount,
        net_price: sale_product.net_price,
        net_total: sale_product.net_total,
        price: sale_product.price,
        price_cash: sale_product.price_cash,
        price_forward: sale_product.price_forward,
        price_profit: sale_product.price_profit,
        product_discount: sale_product.product_discount,
        product_discount_percent: sale_product.product_discount_percent,
        product_discount_unity: sale_product.product_discount_unity,
        product_total: sale_product.product_total,
        quantity: sale_product.quantity,
        shipping: sale_product.shipping,
        unit: sale_product.unit,
        sub_total: sale_product.sub_total,

        product: {
          id: sale_product.product.id
        },

        product_company: {
          id: sale_product.product_company.id
        },

        product_variant: {
          id: sale_product.product_variant.id
        },
      }
    })
  }

}

function calcGeneralDiscountArr(productSubTotals, generalDiscount) {

  let ratio = Vue.$calc.ratio(productSubTotals, generalDiscount)

  let diference = generalDiscount - ratio.reduce((a, b) => a + b, 0)

  ratio[0] = Vue.$calc.round(ratio[0] + diference)

  return ratio
}

export default SaleObject;

