import Vue from "vue"

export default {

  namespaced: true,

  state: () => ({
    products: [],
    //    ;
    selectedProducts: [],
    // localStorage.setItem("token", JSON.stringify(token));
    tag: null,
  }),


  getters: {
    getProducts(state, getters) {
      return localStorage.getItem('products')//state.products

    }
  },
  mutations: {

    resetProducts(state) {
      state.products = []
      state.selectedProducts = []
      localStorage.removeItem('products')
    },

    addProduct(state, { product, quantity }) {

      let products = {
        ...product,
        quantity: quantity,

      };

      state.products.push(products);
    },

    increaseProduct(state, { product, quantity }) {
      let productsData = {
        ...product,
        quantity: quantity,
      };

      state.selectedProducts.push(productsData);

      localStorage.setItem("products", JSON.stringify(state.selectedProducts));



      let productIndex = state.products
        .map(row => row.id)
        .indexOf(product.id);

      if (productIndex >= 0) {
        state.products[productIndex].quantity = parseFloat(state.products[productIndex].quantity) + parseFloat(quantity)
      } else {
        this.commit("productsPrintTag/addProduct", { product: product, quantity: quantity });
      }
    },

    decreaseProduct(state, product) {
      let productIndex = state.products
        .map(row => row.id)
        .indexOf(product.id);

      if (state.products[productIndex]) {
        if (state.products[productIndex].quantity > 1) {
          state.products[productIndex].quantity--;
        } else {
          this.commit("productsPrintTag/removeProduct", product);
        }
      }
    },

    removeProduct(state, product) {
      let productIndex = state.products
        .map(row => row.id)
        .indexOf(product.id);

      state.products.splice(productIndex, 1);
    },
    setCustomTagBase(state, customTag) {
      state.tag = customTag

    }
  },

  actions: {}

}
